// Variables
// -------------------------
@white: #ffffff;
@errorBackground: #a94442;
@errorText2: @white;
@infoBackground: #31708f;
@infoText: @white;
@successBackground: #3c763d;
@successText2: @white;

//
// Color-Classes
// --------------------------------------------------
.portalColor {
  color: @infoBackground;

  &.info {
    color: @infoBackground;
  }
  &.success {
    color: @successBackground;
  }
  &.error {
    color: @errorBackground;
  }
}

.portalBGColor {
  background-color: @infoBackground;

  &.info {
    background-color: @infoBackground;
  }
  &.success {
    background-color: @successBackground;
  }
  &.error {
    background-color: @errorBackground;
  }
}

//
// Messages
// --------------------------------------------------
.portalMessage {
  padding: 8px 35px 8px 14px;
  background-color: @infoBackground;
  color: @infoText;

  &.info {
    background-color: @infoBackground;
    color: @infoText;
    h4 {
      color: @infoText;
    }
  }
  &.success {
    background-color: @successBackground;
    color: @successText2;
    h4 {
      color: @successText2;
    }
  }
  &.error {
    background-color: @errorBackground;
    color: @errorText2;
    h4 {
      color: @errorText2;
    }
  }

  ul {
    margin: 0 20px;
  }
  h4 {
    margin: 0;
    color: @infoText;
  }
  button {
    margin: 10px;
  }
}

//
// Badges
// --------------------------------------------------
.portalBadge {
  display:inline-block;
  padding:0 5px;
  background-color: @infoBackground;;
  color: @infoText;
  font-size: 11px;

  &.info {
    background-color: @infoBackground;
    color: @infoText;
  }
  &.success {
    background-color: @successBackground;
    color: @successText2;
  }
  &.error {
    background-color: @errorBackground;
    color: @errorText2;
  }
}

//
// Form errors
// --------------------------------------------------
.portalFormError {
  color: @errorBackground;

  ul {
    margin: 0 0 0 20px;
  }
}
