// (MERGED WITH ARGUS)
// Typography
// --------------------------------------------------

// BODY TEXT
// ---------

body {
  margin: 0;
  .baseFontFamily();
  font-size: @baseFontSize;
  line-height: @baseLineHeight;
  color: @baseTextColor;
}

p {
  margin: 0 0 @baseLineHeight / 2;
  .baseFontFamily();
  font-size: @baseFontSize;
  line-height: @baseLineHeight;
  small {
    font-size: @baseFontSize - 2;
    color: @grayLight;
  }
}

.lead {
  margin-bottom: @baseLineHeight;
  font-size: @fontSize21;
  font-weight: 200;
  line-height: @baseLineHeight * 1.5;
}

// Utility classes (TW Original)
.muted {
  color: @grayLight;
}

a.muted:hover,
a.muted:focus {
  color: darken(@grayLight, 10%);
}

.text-warning {
  color: @warningText;
}

a.text-warning:hover,
a.text-warning:focus {
  color: darken(@warningText, 10%);
}

.text-error {
  color: @errorText;
}

a.text-error:hover,
a.text-error:focus {
  color: darken(@errorText, 10%);
}

.text-info {
  color: @infoText;
}

a.text-info:hover,
a.text-info:focus {
  color: darken(@infoText, 10%);
}

.text-success {
  color: @successText;
}

a.text-success:hover,
a.text-success:focus {
  color: darken(@successText, 10%);
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

// Links
// -----

a {
  color: @linkColor;
  text-decoration: none;
  outline: none;
  border: none; /* eventually, IE specific, not sure */
}

button {
  outline: none;
}

a:hover {
  color: @linkColorHover;
  text-decoration: none;
}

a:active, a:focus {
  outline: none;
}

a:active, a:focus {
  outline-style: none;
  -moz-outline-style: none;
}

*, *:hover, *:focus, *:active {
  outline: none !important;
  -moz-outline-style: none !important;
}

// HEADINGS
// --------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: (@baseLineHeight / 2) 0;
  .headingsFontFamily();
  color: @headingsColor;
  text-rendering: optimizelegibility; // Fix the character spacing for headings
  small {
    font-weight: normal;
    line-height: 1;
    color: @grayLight;
  }
}

h1, .h1 {
  font-size: @fontSize21 !important;
  color: @color3;
  line-height: 140%;
  .baseFontFamilyBolder();
  small {
    font-size: @fontSize19;
  }
}

h2, .h2, input[type="text"].h2 {
  font-size: @fontSize23 !important;
  line-height: 130%;
  small {
    font-size: @fontSize19;
  }
}

h3, .h3, input[type="text"].h3, input.h3 {
  margin-bottom: @gridSquare * 0.75;
  font-size: @fontSize19 !important;
  line-height: 130%;
  color: @grayDarker;
  small {
    font-size: @fontSize15;
  }

  &.clippingTitle {
    margin-top: @gridSquare * 1.4;
    margin-bottom: @gridSquare * 1.5;
  }
}

h4, h5, h6 {
  line-height: @baseLineHeight;
}

h4, .h4, input[type="text"].h4 {
  font-size: @fontSize17 !important;
  small {
    font-size: @baseFontSize;
  }
}

h5, .h5 {
  font-size: @baseFontSize;
}

h6, .h6 {
  font-size: @fontSize11 !important;
  color: @grayLight;
  text-transform: uppercase;
}

// Page header
// -------------------------

.page-header {
  padding-bottom: (@baseLineHeight / 2) - 1;
  margin: @baseLineHeight 0 (@baseLineHeight * 1.5);
  border-bottom: 1px solid @grayLighter;
}

.page-header h1 {
  line-height: 1;
}

// LISTS
// -----

// Lists
// --------------------------------------------------

// Unordered and Ordered lists
ul, ol {
  padding: 0;
  margin: 0 0 @baseLineHeight / 2 25px;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li {
  line-height: @baseLineHeight;
}

// Remove default list styles
ul.unstyled,
ol.unstyled {
  margin-left: 0;
  list-style: none;
}

// Single-line list items
ul.inline,
ol.inline {
  margin-left: 0;
  list-style: none;
  > li {
    display: inline-block;
    .ie7-inline-block();
    padding-left: 5px;
    padding-right: 5px;
  }
}

// Description Lists
dl {
  margin-bottom: @baseLineHeight;
}

dt,
dd {
  line-height: @baseLineHeight;
}

dt {
  font-weight: bold;
  // the fix of jank was removed: https://github.com/twitter/bootstrap/pull/3821
}

dd {
  margin-left: @baseLineHeight / 2;
}

// Horizontal layout (like forms)
.dl-horizontal {
  .clearfix(); // Ensure dl clears floats if empty dd elements present
  dt {
    float: left;
    width: @horizontalComponentOffset - 20;
    clear: left;
    text-align: right;
    .text-overflow();
  }
  dd {
    margin-left: @horizontalComponentOffset;
  }
}

// MISC
// ----

// Horizontal rules
hr {
  margin: @baseLineHeight 0;
  border: 0;
  border-top: 1px solid @hrBorder;
  border-bottom: 1px solid @white;
}

// Emphasis
strong, b {
  .headingsFontFamily();
  i, em {
    .headingsFontFamilyItalic();
  }
}

em, i {
  font-style: italic;
  .baseFontFamilyItalic();

  strong, b {
    .headingsFontFamilyItalic();
  }
}

.muted {
  color: @grayLight;
}

// Abbreviations and acronyms
abbr[title],
  // Added data-* attribute to help out our tooltip plugin, per https://github.com/twitter/bootstrap/issues/5257
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #ddd;
}

abbr.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

// Blockquotes
blockquote {
  padding: 0 0 0 15px;
  margin: 0 0 @baseLineHeight;
  border-left: 5px solid @grayLighter;
  p {
    margin-bottom: 0;
    #font > .shorthand(16px, 300, @baseLineHeight * 1.25);
    // (TW Original)
    //font-size: @baseFontSize * 1.25;
    //font-weight: 300;
    //line-height: 1.25;

  }
  small {
    display: block;
    line-height: @baseLineHeight;
    color: @grayLight;
    &:before {
      content: '\2014 \00A0';
    }
  }

  // Float right with text-align: right
  &.pull-right {
    float: right;
    padding-right: 15px;
    padding-left: 0;
    border-right: 5px solid @grayLighter;
    border-left: 0;
    p,
    small {
      text-align: right;
    }
    small {
      &:before {
        content: '';
      }
      &:after {
        content: '\00A0 \2014';
      }
    }
  }
}

// Quotes
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
}

// Addresses
address {
  display: block;
  margin-bottom: @baseLineHeight;
  font-style: normal;
  line-height: @baseLineHeight;
}

// Misc
small {
  font-size: 100%;
}

cite {
  font-style: normal;
}

