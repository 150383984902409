@spritePath: "/build/img/spritecolorOwnNeu.png";
@gridSquareSprite: 40px;

.background-sprite(@posX, @posY) {
  background: transparent url(@spritePath) no-repeat -@posX*@gridSquareSprite -@posY*@gridSquareSprite;
}

.background-sprite-pos(@posX, @posY) {
  background-position: -@posX*@gridSquareSprite -@posY*@gridSquareSprite;
}

[class^="iconImg-"],
[class*=" iconImg-"],
[class^="iconImgGray-"],
[class*=" iconImgGray-"],
.icon-docart-10, .icon-docart-80 {
  display: inline-block;
  width: 26px;
  height: 26px;
  vertical-align: text-top;
  .background-sprite(0, 0);
  position: relative;
  top: -2px;
  left: 0;
}

[class^="iconImgMnu-"],
[class*=" iconImgMnu-"],
.icon-docart-10, .icon-docart-80 {
  display: inline-block;
  width: 26px;
  height: 26px;
  vertical-align: middle;
  .background-sprite(0, 0);
  position: relative;
  top: 2px;
  left: 2px;
}

[class^="iconImgSmall-"],
[class*=" iconImgSmall-"] {
  display: inline-block;
  width: 13px;
  height: 13px;
  vertical-align: text-top;
  .background-sprite(0, 0);
  position: relative;
  top: 2px;
  left: 0;
}

[class^="iconSuperBig-"],
[class*=" iconSuperBig-"] {
  display: inline-block;
  width: 120px;
  height: 80px;
  vertical-align: text-top;
  .background-sprite(0, 0);
}

.mobileTagArrow {
  width: 13px;
  height: 30px;
  vertical-align: text-top;
  display: table-cell;
  vertical-align: middle;
  .background-sprite(11, 10);
}
.mobileTagSpan {
  min-height: 30px;
  padding: 5px 10px;
  background-color: #828282;
  display: table-cell;
  vertical-align: middle;
}

[class^="iconShare-"],
[class*=" iconShare-"] {
  display: inline-block;
  width: 46px;
  height: 46px;
  vertical-align: text-top;
  .background-sprite(0, 0);
}

.iconImg-medienart1 {
  .background-sprite-pos(14, 7);
}

a:hover .iconImg-medienart1,
.btn:hover .iconImg-medienart1 {
  .background-sprite-pos(14, 8);
}

a:active .iconImg-medienart1,
.btn:active .iconImg-medienart1 {
  .background-sprite-pos(14, 9);
}

.iconImgSmall-medienart1 {
  .background-sprite-pos(14, 6);
}

.iconImgGray-medienart1 {
  .background-sprite-pos(14, 5);
}

.iconImg-medienart6 {
  .background-sprite-pos(9, 7);
}

a:hover .iconImg-medienart6,
.btn:hover .iconImg-medienart6 {
  .background-sprite-pos(9, 8);
}

a:active .iconImg-medienart6,
.btn:active .iconImg-medienart6 {
  .background-sprite-pos(9, 9);
}

.iconImgSmall-medienart6 {
  .background-sprite-pos(9, 6);
}

.iconImgGray-medienart6 {
  .background-sprite-pos(9, 5);
}

.iconImg-medienart7 {
  .background-sprite-pos(8, 7);
}

a:hover .iconImg-medienart7,
.btn:hover .iconImg-medienart7 {
  .background-sprite-pos(8, 8);
}

a:active .iconImg-medienart7,
.btn:active .iconImg-medienart7 {
  .background-sprite-pos(8, 9);
}

.iconImgSmall-medienart7 {
  .background-sprite-pos(8, 6);
}

.iconImgGray-medienart7 {
  .background-sprite-pos(8, 5);
}

.iconImg-medienart4 {
  .background-sprite-pos(15, 7);
}

a:hover .iconImg-medienart4,
.btn:hover .iconImg-medienart4 {
  .background-sprite-pos(15, 8);
}

a:active .iconImg-medienart4,
.btn:active .iconImg-medienart4 {
  .background-sprite-pos(15, 9);
}

.iconImgSmall-medienart4 {
  .background-sprite-pos(15, 6);
}

.iconImgGray-medienart4 {
  .background-sprite-pos(15, 5);
}

.iconImg-medienart3 {
  .background-sprite-pos(13, 7);
}

a:hover .iconImg-medienart3,
.btn:hover .iconImg-medienart3 {
  .background-sprite-pos(13, 8);
}

a:active .iconImg-medienart3,
.btn:active .iconImg-medienart3 {
  .background-sprite-pos(13, 9);
}

.iconImgSmall-medienart3 {
  .background-sprite-pos(13, 6);
}

.iconImgGray-medienart3 {
  .background-sprite-pos(13, 5);
}

.iconImg-medienart2 {
  .background-sprite-pos(10, 7);
}

a:hover .iconImg-medienart2,
.btn:hover .iconImg-medienart2 {
  .background-sprite-pos(10, 8);
}

a:active .iconImg-medienart2,
.btn:active .iconImg-medienart2 {
  .background-sprite-pos(10, 9);
}

.iconImgSmall-medienart2 {
  .background-sprite-pos(10, 6);
}

.iconImgGray-medienart2 {
  .background-sprite-pos(10, 5);
}

.iconImg-medienart15 {
  .background-sprite-pos(11, 7);
}

a:hover .iconImg-medienart15,
.btn:hover .iconImg-medienart15 {
  .background-sprite-pos(11, 8);
}

a:active .iconImg-medienart15,
.btn:active .iconImg-medienart15 {
  .background-sprite-pos(11, 9);
}

.iconImgSmall-medienart15 {
  .background-sprite-pos(11, 6);
}

.iconImgGray-medienart15 {
  .background-sprite-pos(11, 5);
}

.iconImg-pdf {
  .background-sprite-pos(11, 7);
}

.icon-docart-10, .icon-docart-80 {
  .background-sprite-pos(2, 11);
}

a:hover .icon-docart-10,
.btn:hover .icon-docart-10 ,
a:hover .icon-docart-80,
.btn:hover .icon-docart-80 {
  .background-sprite-pos(2, 12);
}

a:active .icon-docart-10,
.btn:active .icon-docart-10,
a:active .icon-docart-80,
.btn:active .icon-docart-80 {
  .background-sprite-pos(2, 13);
}

.iconImgSmall-facebook {
  .background-sprite-pos(12, 6);
}

a:hover .iconImgSmall-facebook,
.btn:hover .iconImgSmall-facebook {
  .background-sprite-pos(12, 6);
}

a:active .iconImgSmall-facebook,
.btn:active .iconImgSmall-facebook {
  .background-sprite-pos(12, 6);
}

.iconImgSmall-twitter {
  .background-sprite-pos(11, 6);
}

a:hover .iconImgSmall-twitter,
.btn:hover .iconImgSmall-twitter {
  .background-sprite-pos(11, 6);
}

a:active .iconImgSmall-twitter,
.btn:active .iconImgSmall-twitter {
  .background-sprite-pos(11, 6);
}

.iconImgSmall-gplus {
  .background-sprite-pos(5, 5);
}

a:hover .iconImgSmall-gplus,
.btn:hover .iconImgSmall-gplus {
  .background-sprite-pos(5, 5);
}

a:active .iconImgSmall-gplus,
.btn:active .iconImgSmall-gplus {
  .background-sprite-pos(5, 5);
}

.iconImgMnu-expand {
  .background-sprite-pos(9, 13);
}

a:hover .iconImgMnu-expand,
.btn:hover .iconImgMnu-expand {
  .background-sprite-pos(9, 13);
}

a:active .iconImgMnu-expand,
.btn:active .iconImgMnu-expand {
  .background-sprite-pos(9, 13);
}

.iconImgMnu-reduce {
  .background-sprite-pos(9, 14);
}

a:hover .iconImgMnu-reduce,
.btn:hover .iconImgMnu-reduce {
  .background-sprite-pos(9, 14);
}

a:active .iconImgMnu-reduce,
.btn:active .iconImgMnu-reduce {
  .background-sprite-pos(9, 14);
}

.iconSuperBig-dossier {
  .background-sprite-pos(1, 18);
}

.iconSuperBig-pressreview {
  .background-sprite-pos(4, 18);
}

.iconSuperBig-newsletter {
  .background-sprite-pos(7, 18);
}

.iconSuperBig-ali {
  .background-sprite-pos(13, 20);
}

.iconSuperBig-report {
  .background-sprite-pos(10, 18);
}

.iconSuperBig-presssummary {
  .background-sprite-pos(16, 20);
}

.iconSuperBig-tags {
  .background-sprite-pos(13, 18);
}

.iconSuperBig-auftragsverwaltung {
  .background-sprite-pos(16, 16);
}

.iconSuperBig-rss {
  .background-sprite-pos(16, 18);
}

.iconSuperBig-verteiler {
  .background-sprite-pos(7, 20);
}

.iconSuperBig-filterliste {
  .background-sprite-pos(4, 20);
}

.iconShare-shareGooglePlus {
  .background-sprite-pos(18, 1);
}
.iconShare-shareFacebook {
  .background-sprite-pos(18, 3);
}
.iconShare-shareTwitter {
  .background-sprite-pos(18, 5);
}

.iconMedienart1Simple{
  background:url("/build/img/medienart/medienart1.png") no-repeat;
}
.iconMedienart2Simple{
  background:url("/build/img/medienart/medienart2.png") no-repeat;
}
.iconMedienart3Simple{
  background:url("/build/img/medienart/medienart3.png") no-repeat;
}
.iconMedienart4Simple{
  background:url("/build/img/medienart/medienart4.png") no-repeat;
}
.iconMedienart6Simple{
  background:url("/build/img/medienart/medienart6.png") no-repeat;
}
.iconMedienart7Simple{
  background:url("/build/img/medienart/medienart7.png") no-repeat;
}