.icon-0-5x {
  font-size: 0.5em;
}
.icon-0-8x {
  font-size: 0.8em !important;
}
.icon-0-95x {
  font-size: 0.95em !important;
}

.icon-1x {
  font-size: 1.0em;
}

.icon-1-5x {
  font-size: 1.5em;
}

.icon-2x {
  font-size: 2em;
}

.icon-3x {
  font-size: 3em;
}

.icon-4x {
  font-size: 4em;
}

.icon_small {
  font-size: 14px !important;
  padding: 2px;
}

.icon_middle {
  font-size: 16px;
  padding: 1px;
}

.icon_big {
  font-size: 20px;
}

.icon-tonality_db_big {
  font-size: 80px;
}

.icon-tonality_db_small {
  color: @grayLighterBorder;
  font-size: 40px;
}

a *[class*="icon-"] {
  color: @textColor;
}


i.previewIcon {
  color: @previewIconColor !important;
}

i.lightIcon {
  color: @lightenTextColor !important;
}

.icon-medienart-1, .icon-medienart-2, .icon-medienart-3, .icon-medienart-4, .icon-medienart-6, .icon-medienart-7,
.icon-doc-pdf, .icon-doc-txt, .icon-doc-video, .icon-doc-audio, .icon-doc-word, .icon-doc-excel, .icon-doc-ppt, .icon-doc-url, .icon-doc-xml, .icon-doc-html,
.icon-docart-20, .icon-docart-22, .icon-docart-23, .icon-docart-25, .icon-docart-26, .icon-docart-30, .icon-docart-31, .icon-docart-32, .icon-docart-35,
.icon-docart-36, .icon-docart-37,
.icon-docart-60, .icon-docart-65, .icon-docart-81, .icon-docart-82, .icon-docart-83, .icon-docart-84, .icon-docart-126,
.icon-docart-33,.icon-docart-85,.icon-docart-34, .icon-docart-image,
.icon-docart-10-mobile, .icon-docart-80-mobile,
.icon-docart-86, .icon-docart-87, .icon-docart-88,
.icon-docat-130, .icon-docat-131, .icon-docat-132, .icon-docat-133,
.icon-docat-171, .icon-docat-172, .icon-docat-173
{
 //.icon-tumblr();
  font-family: 'font-icomoon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.icon-medienart-1:before {
  content: "\e6fc";
}

.icon-medienart-2:before {
  content: "\e1bf";
}

.icon-medienart-3:before {
  content: "\e713";
}

.icon-medienart-4:before {
  content: "\e682";
}

.icon-medienart-6:before {
  content: "\e000";
}

.icon-medienart-7:before {
  content: "\e6aa";
}

.icon-doc-pdf:before, .icon-docart-10-mobile:before, .icon-docart-80-mobile:before {
  content: "\e6fc";
}

.icon-doc-txt:before, .icon-docart-22:before, .icon-docart-81:before {
  content: "\e61b";
}

.icon-doc-audio:before, .icon-docart-30:before, .icon-docart-31:before, .icon-docart-32:before, .icon-docart-34:before,
.icon-docart-36:before, .icon-docart-37:before, .icon-docart-87:before, .icon-docart-132:before, .icon-docart-133:before{
  content: "\e713";
}

.icon-docart-131:before {
  content: "\e713";
  color: @grayLight;
}

.icon-doc-radio:before, .icon-docart-35:before, .icon-docart-86:before, .icon-docart-172:before, .icon-docart-173:before {
  content: "\e6aa";
}

.icon-docart-171:before {
  content: "\e6aa";
  color: @grayLight;
}

.icon-doc-image:before, .icon-docart-33:before, .icon-docart-85:before, .icon-docart-88:before, .icon-docart-130:before {
  content: "\e60c";
}

.icon-doc-word:before, .icon-docart-25:before, .icon-docart-26:before, .icon-docart-83:before, .icon-docart-126:before {
  content: "\e6fe";
}

.icon-doc-excel:before, .icon-docart-82:before {
  content: "\e6ff";
}

.icon-doc-ppt:before, .icon-docart-84:before {
  content: "\e701";
}

.icon-doc-url:before {
  content: "\e682";
}

.icon-doc-xml:before, .icon-docart-20:before, .icon-docart-65:before {
  content: "\e702";
}

.icon-doc-html:before, .icon-docart-23:before {
  content: "\e702";
}
