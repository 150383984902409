.overlay{
  background: black;
  opacity: .5;
  position:fixed;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  z-index:10;
}
.box{
  position:fixed;
  top:-200px;
  left:30%;
  right:30%;
  background-color:#fff;
  color:#7F7F7F;
  padding:20px;
  border:2px solid #ccc;
  -moz-border-radius: 20px;
  -webkit-border-radius:20px;
  -khtml-border-radius:20px;
  -moz-box-shadow: 0 1px 5px #333;
  -webkit-box-shadow: 0 1px 5px #333;
  z-index:101;
}
a.boxclose{
  float:right;
  margin-top:-23px;
  margin-right:-2px;
  cursor:pointer;
  color: #fff;
  border: 1px solid #AEAEAE;
  background: #605F61;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  line-height: 0px;
  padding: 11px 5px;
}

a.boxCancel{
  float:right;
  margin-top:-23px;
  margin-right:2px;
  cursor:pointer;
  color: #fff;
  border: 1px solid #AEAEAE;
  background: #605F61;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  line-height: 0px;
  padding: 11px 5px;
}

a.boxBorderButton {
  float:right;
  margin-top:-26px;
  cursor:pointer;
  color: @btnColor;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #AEAEAE;
  /*border: 1px solid #AEAEAE;*/
  background-color: @btnBackground !important;
  font-size:13px;
  display: inline-block;
  padding: 4px 8px;
  text-decoration: none;

  &:hover, &:active {
    background-color: @btnBackgroundHover !important;
    color: @btnColorHover;
  }

}

.boxclose:before {
 /* content: "Done";*/

}

.box h1{
  border-bottom: 1px dashed #7F7F7F;
  margin:-20px -20px 0px -20px;
  padding:10px;
  background-color:#FFEFEF;
  color:#EF7777;
  -moz-border-radius:20px 20px 0px 0px;
  -webkit-border-top-left-radius: 20px;
  -webkit-border-top-right-radius: 20px;
  -khtml-border-top-left-radius: 20px;
  -khtml-border-top-right-radius: 20px;
}
