//
// Responsive: Large desktop and up
// --------------------------------------------------

@media (min-width: @screenSizeL-min) {

  // Fixed grid
  #grid > .core(@gridColumnWidth1200, @gridGutterWidth1200);

  // Fluid grid
  #grid > .fluid(@fluidGridColumnWidth1200, @fluidGridGutterWidth1200);

  // Input grid
  #grid > .input(@gridColumnWidth1200, @gridGutterWidth1200);

  // Thumbnails
  .thumbnails {
    margin-left: -@gridGutterWidth1200;
  }

  .thumbnails > li {
    margin-left: @gridGutterWidth1200;
  }

  .row-fluid .thumbnails {
    margin-left: 0;
  }

  #fixedHeader {
    max-width: 1248px;
  }

  #contentLeft {
    width: 50%;
  }

  #paginationFooterWraper {
    width: 624px !important;
  }

  .nav-collapse.collapse {
    //float: left;
    overflow: visible;
  }

}

@media (min-width: @screenSizeM-min) and (max-width: @screenSizeL-max) {
  #contentLeft {
    width: 50%;
  }

  #paginationFooterWraper {
    width: 480px;
  }

  .nav-collapse.collapse {
    overflow: visible;
  }

  .navbar .nav {
    margin-right: 0;
  }
}
