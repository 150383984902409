.tile-grid {
  /*padding: 0 2%;*/
  font-size: 13px; // Static value is necessary for responsive font behaviour (see below)
  line-height: 1.2em;

  .tile-count {
    padding-left: 0.6%;
  }

  // Standard tile behaviour

  .tile {
    float: left;
    display: inline-block;
    position: relative;
    overflow: hidden;
    // padding: 0.6%;
    margin: 2px !important;

    border: 2px solid @bodyBackground !important;

    &:hover {
      border: 2px solid @color3 !important;
    }

    .stretcher {
      cursor: pointer;
      background-color: @color1;
    }

    .content {
      color: @white;
    }

    .content_list {
      color: @colorTileArticleList;
      .dynamic_teaser {
        font-size: 1.1em;
      }
    }

    .content, .content_list {
      top: 0;
      left: 0;
      padding-bottom: 0px !important;
    }

    .info {
      color: @white;

      .divider {
        border-top: 1px solid @white;
        height: 1px;
        margin-bottom: 5px;
        width: 40px;
      }
    }

    .info_list {
      color: @colorTileArticleList;
      .divider {
        border-top: 1px solid @colorTileArticleList;
        height: 1px;
        margin-bottom: 5px;
        width: 40px;
        font-size: 1.1em;
      }
    }

    .info, .info_list {
      bottom: 0;
      left: 0;
      opacity: 0.666;
    }

    .content, .info, .content_list, .info_list {
      cursor: pointer;
      position: absolute;
    }

    h2 {
      font-size: 1.3em !important;
      color: @white;
      line-height: 1em;
      font-weight: 400;
      margin: 0 0 4px 0;
      &.shadow {
        text-shadow: 1px 1px 1px #000;
      }
    }

    .articles {
      position: absolute;
      color: @color2;
      bottom: 0;
      left: 0;
      background-color: @color3;
      font-weight: bold;
      height: 1.7em;
      line-height: 1.7em;
      text-align: center;
      width: 1.7em;

    }

    .articlesCount {
      margin: 15px 15px !important; // added UJ
      padding: 0 !important; // added UJ
      width: 1.7em !important; // added UJ
    }
  }

  // 1x1 tile

  .tile.span2 {
    // width: 16.666%;
    width: 16%;

    .stretcher {
      padding-top: 100%;

      &.media-print {
        background-color: @medienart1-darker;
      }

      &.media-agency {
        background-color: @medienart2-darker;
      }

      &.media-online {
        background-color: @medienart4-darker;
      }

      &.media-tv {
        background-color: @medienart3-darker;
      }

      &.media-video {
        background-color: @medienart6-darker;
      }

      &.media-radio {
        background-color: @medienart7-darker;
      }
    }

    .content, .info, .articles {
      //padding: 15px 16px 15px 16px;
      padding: 8px;
      width: 100%;
      //margin: 8% 9%; // ~16px; UJ
    }

    .divider {
      width: 30px;
    }

    .info {
      font-size: 0.9em;
      line-height: 1.2em;
    }

    .articles {
      font-size: 1.8em;
    }
  }

  // 2x2 tile

  .tile.span4 {
    // width: 33.333333%;
    // width: 33.332%;
    width: 32%;

    .stretcher {
      padding-top: 99.8%;

      &.media-print {
        background-color: @medienart1;
      }

      &.media-agency {
        background-color: @medienart2;
      }

      &.media-online {
        background-color: @medienart4;
      }

      &.media-tv {
        background-color: @medienart3;
      }

      &.media-video {
        background-color: @medienart6;
      }

      &.media-radio {
        background-color: @medienart7;
      }
    }

    .content, .info, .articles {
      // padding: 15px 16px 15px 16px;
      padding: 8px;
      width: 100%;
    }

    .info {
      // PN
      font-size: 0.9em;
      line-height: 1.2em;
    }

    .articles {
      font-size: 1.95em;
    }
  }

  // 2x1 tile

  .tile.span4.tile-flat {

    .stretcher {
      padding-top: 50%; // 48.22%; // 50% - ~2% for the borders //UJ 48.77

      &.media-print {
        background-color: @medienart1-dark;
      }

      &.media-agency {
        background-color: @medienart2-dark;
      }

      &.media-online {
        background-color: @medienart4-dark;
      }

      &.media-tv {
        background-color: @medienart3-dark;
      }

      &.media-video {
        background-color: @medienart6-dark;
      }

      &.media-radio {
        background-color: @medienart7-dark;
      }
    }

    .content, .info, .articles {
      // padding: 15px 16px 15px 16px;
      padding: 8px; // PN
      width: 100%;
    }

    .info {
      // PN
      font-size: 0.9em;
      line-height: 1.2em;
    }

    .articles {
      font-size: 1.9em;
    }
  }

  // Responsive behaviour for mobile devices (horizontal)
  // @navbarCollapseWidth:             979px;
  @media (max-width: 960px) {

    .tile {
      // padding: 0.8%;
      // margin: 2px;
    }

    // 1x1 tile
    .tile.span2 {
      // width: 25%;
      width: 23.9%;
    }

    // 2x2 tile
    .tile.span4 {
      // width: 50%;
      width: 48%;
    }

    // 2x1 tile
    .tile.span4.tile-flat {

      .stretcher {
        padding-top: 49.9%; // 48.35%; //UJ 49.189%; // 50% - ~0,8% for the borders
      }
    }

    // same behaviour
  }

  // responsive-767px-max.less
  @media (max-width: 600px) {
    .tile {
      // padding: 1%;
      // margin: 3px;
    }

    // 1x1 tile
    .tile.span2 {
      width: 49%;
    }

    // 2x2 tile
    .tile.span4 {
      width: 98%;
    }

    // list
    .tile.span12 {
      width: 98%;
    }

  }

  // Responsive behaviour for mobile devices (vertical)
  @media (max-width: 519px) {

    .tile {
      // padding: 1%;
      //margin: 3px;
    }

    // 1x1 tile
    .tile.span2 {
      width: 48.7%;
    }

    // 2x2 tile
    .tile.span4 {
      width: 98%;
    }

    // list
    .tile.span12 {
      width: 98%;
    }

    // 2x1 tile
    // same behaviour
  }

  // No padding, if the page is
  @media (min-width: @navbarCollapseWidth) {
    padding: 0 0;
  }

  // This is necessary to compensate the extra 20px margin on the <body> element
  @media (max-width: @navbarCollapseWidth) {
    /*margin-left: -20px;
    margin-right: -20px;*/
  }
}