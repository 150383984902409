// (MERGED WITH ARGUS)
// Variables
// --------------------------------------------------

// Global values
// --------------------------------------------------

// Sprite path
// -------------------------
@spritePath: "/build/img/sprite.png";

// mobile from outermedia
// -------------------------
@ausschnitt-orange: #f39325; //#faa61a;
@orange-darker:  #df9210;
@ausschnitt-blue: #0070B7;
@ausschnitt-darkgray: #4d4d4d;
@ausschnitt-lightgrey: #f0f0f0;

// Grays (TB)
// -------------------------
@black:                 #000;
@grayDarker:            #222;
@grayDark:              #333;
@gray:                  #555;
@grayLight:             #999;
@grayLightMiddle:       #8C9198;
@grayLighter:           #eee;
@white:                 #fff;

// Argus Grays
// -------------------------
@blackRGBA50: rgba(0, 0, 0, 0.5);

@grayDarkerFont: #181c21;
@grayDarker: #323A44;
@grayDark: #3F4854;
@gray: @gray3;
@grayLight: @gray4;
@grayLighterBorder: @gray9;
@grayLighter: @gray10;
@grayCD: @gray9; //<- CD gray
@grayInputBorder: @gray6;


// colors
// -------------------------
@mainBkColor:           #f39325; //#faa61a; //#2590da;
@mainLinkColor:         #0070B7; //#1a4587;
@mainBlueColor:         #00203E;
@mainOrangeColor:       #f39325;

@mainBlueColor70:       #4d6378;
@mainBlueColor50:       #808f9e;
@mainBlueColor40:       #b3bcc5;
@mainBlueColor30:       #b3bcc5;
@mainBlueColor20:       #ccd2d8;
@mainBlueColor10:       #e6e9ec;

@mainOrangeColor70:       #f6b366;
@mainOrangeColor50:       #f9c992;
@mainOrangeColor30:       #fbdebe;
@mainOrangeColor20:       #fce9d3;
@mainOrangeColor10:       #fdf4e9;

@ausschnittLightBlue100: #0070b7;
@ausschnittLightBlue70:  #4d9bcc;
@ausschnittViolet100:    #392346;
@ausschnittViolet70:     #74657d;
@ausschnittRed100:       #cd1719;
@ausschnittRed70:        #dc5d5e;
@ausschnittGreen100:     #35a54a;
@ausschnittGreen100:     #72c080;

@ausschnittBlack100: #000000;
@ausschnittBlack70:  #4d4d4d;
@ausschnittBlack50:  #808080;
@ausschnittBlack40:  #999999;
@ausschnittBlack30:  #b3b3b3;
@ausschnittBlack20:  #cccccc;
@ausschnittBlack10:  #e6e6e6;

@gray1:                 #4d4d4d;
@gray2:                 #5f5f5f;
@gray3:                 #717171;
@gray4:                 #828282;
@gray5:                 #949494;
@gray6:                 #a6a6a6;
@gray7:                 #b8b8b8;
@gray8:                 #cacaca;
@gray9:                 #dedede;
@gray10:                #f0f0f0;

@blue:                  #049cdb;
@blueDark:              #0064cd;
@grayBlue:              #557294;
@green:                 #46a546;
@red:                   #9d261d;
@yellow:                #ffc40d;
@orange:                @mainBkColor;
@pink:                  #c3325f;
@purple:                #7a43b6;

@ampelRed:              @tonality5;
@ampelYellow:           @tonality3;
@ampelGreen:            @tonality1;
@ampelRedShade:         lighten(@ampelRed, 80%);
@ampelYellowShade:      lighten(@ampelYellow, 80%);
@ampelGreenShade:       lighten(@ampelGreen, 80%);

@tonality1:             #23814d;
@tonality2:             #92af1b;
@tonality3:             #ffe100;
@tonality4:             #f39325; //#faa61a;
@tonality5:             #c42227;
@tonalityUnbewertet:    #828282;

@colorTileArticleList: @gray1;

@medienart0:            @gray10; // @gray9;
@medienart0-dark:       @gray5;
@medienart0-darker:     @gray1;
@medienart0hover:       @gray8;

@medienart1:            #1baae2;
@medienart1-dark:       #008fc2;
@medienart1-darker:     #007eab;
@medienart1hover:       #5db0d1;

@medienart2:            #23814d;
@medienart2-dark:       #1e6e42;
@medienart2-darker:     #1a613a;
@medienart2hover:       #478a64;

@medienart3:            #c42227;
@medienart3-dark:       #a71d21;
@medienart3-darker:     #93191d;
@medienart3hover:       #cd3a42;

@medienart4:            #92af1b;
@medienart4-dark:       #7c9417;
@medienart4-darker:     #6d8214;
@medienart4hover:       #a7b85f;

@medienart6:            #453484;
@medienart6-dark:       #3b2c70;
@medienart6-darker:     #342763;
@medienart6hover:       #504191;

@medienart7:            #782f67;
@medienart7-dark:       #662858;
@medienart7-darker:     #5a234d;
@medienart7hover:       #784b75;

@alertError:            @tonality5;
@alertSuccess:          @tonality1;

// Argus colors
// -------------------------
@blueDarker: #175886;
@blueDark: #1F76B3;
@blueMiddle: @orange;//#0E2B8D; //<- this is CD blue //#2590DA;
@blueLight: #EEF9FF;
@realBlueMiddle: #0E2B8D;

@green: #66CC66;
@greenLight: #E8F7E8;
@red: #E75959;
@redLight: #FBE6E6;
@orangeLight: #FFF5E1;
@yellow: #FFFF66;
@alertColor: @tonality5;


//colors, might be changes by user
// -------------------------
// WARN: after change please change PortalColorDesignService->getDefaultObject() too !!!
@color0: @white; // (Color: FilterText )
@color1: @mainBlueColor; //@gray1; // (Bk: Filter, Buttons, Blackboard, ClDetail Accordion Color: Mainmenue+Dashboard-Tab-Active, Filter-sel-hover, Border PSP-tiles)
@color2: @white; // (Color: Clipboard, FilterToggleColor, Modal-header DB, backButton psp-tiles)
@color3: @mainBkColor; // (Bk: Clipboard, Button-hover, Menue-hover, Menue-active, Mainmenue+Dashboard-Tab-Active, Modal-header DB/Filter, Filter-apply-button, backButton psp-tiles  Color: Filter-button-hover, Msg no data, Border: Form-Input active)
@color4: @white; // (Color: FilterSelectInputFont, Dashboard-Tab-hover) (corresponding to Color Tag)
@color5: @ausschnittBlack40; // (BK: FilterSelectInput, Dashboard-Tab-hover) (corresponding to BK-Tag)
@color6: @grayDark; // (Color: main text)
@color7: @ausschnittBlack10; // (BK: usrAdmHeadline, TrefferDetails-BK, Clippinglist-SelectedArticle, Pressespiegel+Clippings-Action-menu, Detailview in psp-Tiles )
@color8: @grayLight; // (Color: FilterText)
@color9: @mainLinkColor; // (Color: Main-link, NavigationInactive, Header Dashboard)
@color10: @white; // (Bk: Export, Color: Text Blackboard, Color1-Buttons)
@color11: @grayBlue; // reserved
@color12: @white; // reserved
@color13: @mainOrangeColor; // reserved

@clItemNewLoadedColor: @color3;

// colors used at pandora views
// -------------------------
// user admin
@usrAdmHeadlineColor:       @color6;
@usrAdmHeadlineBkColor:     @color7;
@usrAdmEditFieldBkColor:    @color7;
@usrAdminBorder:            @color1;
@usrAdminBorderLight:       @color5;

@exportEditFieldBkColor:    @color10;
//blackboard
@boardBackground:           @color1;
@boardTextColor:            @color10;
@textColorOnDarkenBackground: @color10;
@blackboardIconColor:       @color3;

// productlist
@productsListBorder:        @color5;

@selArticleBkColor:         @color7;
@clDetailColor:             @textColor;
@clDetailLeftColor:         lighten(@textColor,20%);
@highlightDetailColor:      lighten(@color3, 30%);

// Header
@clipBoardBkColor:         @color3;
@clipBoardColor:           @color2;


//Filter
@filterBkColor:            @color1;
@filterColor:              @color8;
@filterSelectColor:        @color5; /*dashboardHoverTabBkColor*/
@filterSelectFontColor:    @color4; /*dashboardHoverTabColor*/
@filterToggleColor:        @color3;
@filterToggleFontColor:    @color2;
@filterElementSelectHoverColor: @color3;
@filterElementSelectHoverTextColor: @color2;

@dbSelectColor: @bodyBackground;

@tagColor: @color5;

//modal

@modalHeaderColor:   @color2;
@modalHeaderBkColor: @color3;

//ClippingDetail
@gradientTop:         @color1; // #596371;
@gradientBottom:      @color1; //
@accordionBackground: @white;

//Shadow
.shadow(@color: #222222, @darker: 15%){
  -moz-box-shadow: 0px 4px 4px -4px darken(@color, @darker);
  -webkit-box-shadow: 0px 4px 4px -4px darken(@color, @darker);
  box-shadow: 0px 4px 4px -4px darken(@color, @darker);
}

//Shadow
.filterSelectShadow(@color: #222222, @darker: 50%){
  -moz-box-shadow: 1px 1px 2px 0px darken(@color, @darker);
  -webkit-box-shadow: 1px 1px 2px 0px darken(@color, @darker);
  box-shadow: 1px 1px 2px 0px darken(@color, @darker);
}
//Shadow
.filterSelectShadowNone(){
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none  !important;
  box-shadow: none !important;
}

// Scaffolding Argus
// -------------------------
@bodyBackground:          @white;
@detailsBackground:       @grayLighter;
@bodyHoverBgColor:        @gray10;
@elementSelectedBgColor:  @color7;
@elementSelectedColor:    @color3;
@baseTextColor:           @grayDark;
@textColor:               @color6; //Schriftfarbe
@lightenTextColor:        lighten(@color6, 30%); //Schriftfarbe
@previewIconColor:        lighten(@color6, 55%); //Schriftfarbe
@headingsColor:           @color3; // inherit;
// empty to use BS default, @baseTextColor

// Links
// -------------------------
@linkColor:             @color9; // #08c;
@linkColorHover:        darken(@linkColor, 15%);

// Links Argus
// -------------------------
@linkColorNegative: @color10;
@linkColorHoverNeg: @blueLight;


@adobeRed:        #F91C0A;
@wordBlue:        #285498;
@excelGreen:      #207044;
@twitterBlue:     #1DCAFF;
@facebookBlue:    #3b5998;

// Paths

@asseticPath: "../../src/Ausschnitt/CoreBundle/Resources/public";

// Typography
// -------------------------


@font-face {
  font-family: 'CentraleSansBold';
  src: url('/build/fonts/22EF09_0_0.eot');
  src: url('/build/fonts/22EF09_0_0.eot?#iefix') format('embedded-opentype'), url('/build/fonts/22EF09_0_0.woff') format('woff'), url('/build/fonts/22EF09_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'CentraleSansMedium';
  src: url('/build/fonts/22EF09_1_0.eot');
  src: url('/build/fonts/22EF09_1_0.eot?#iefix') format('embedded-opentype'), url('/build/fonts/22EF09_1_0.woff') format('woff'), url('/build/fonts/22EF09_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'CentraleSansRegular';
  src: url('/build/fonts/2356BD_0_0.eot');
  src: url('/build/fonts/2356BD_0_0.eot?#iefix') format('embedded-opentype'), url('/build/fonts/2356BD_0_0.woff') format('woff'), url('/build/fonts/2356BD_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'CentraleSansRegularItalic';
  src: url('/build/fonts/236AC3_1_0.eot');
  src: url('/build/fonts/236AC3_1_0.eot?#iefix') format('embedded-opentype'), url('/build/fonts/236AC3_1_0.woff') format('woff'), url('/build/fonts/236AC3_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'CentraleSansBoldItalic';
  src: url('/build/fonts/236AC3_2_0.eot');
  src: url('/build/fonts/236AC3_2_0.eot?#iefix') format('embedded-opentype'), url('/build/fonts/236AC3_2_0.woff') format('woff'), url('/build/fonts/236AC3_2_0.ttf') format('truetype');
}

@font-face {
  font-family: 'CentraleSansMediumItalic';
  src: url('/build/fonts/236AC3_0_0.eot');
  src: url('/build/fonts/236AC3_0_0.eot?#iefix') format('embedded-opentype'), url('/build/fonts/236AC3_0_0.woff') format('woff'), url('/build/fonts/236AC3_0_0.ttf') format('truetype');
}

@sansFontFamily:        'Calibri', "Helvetica Neue", Helvetica, Arial, sans-serif;
@serifFontFamily:       Georgia, "Times New Roman", Times, serif;
@monoFontFamily:        Monaco, Menlo, Consolas, "Courier New", monospace;

@baseFontFamily:        @sansFontFamily;
@baseLineHeight:        20px;
@altFontFamily:         @serifFontFamily;

@headingsFontFamily:    inherit; // empty to use BS default, @baseFontFamily
@headingsFontWeight:    bold;    // instead of browser default, bold
@headingsColor:         @color3; //inherit; // empty to use BS default, @textColor

.baseFontFamily {
  font-family: @sansFontFamily;
  font-weight: 400;
  font-style: normal;
  zoom: 1;
}

.baseFontFamilyItalic {
  font-family: @sansFontFamily;
  font-weight: 400;
  font-style: italic;
  zoom: 1;
}

.baseFontFamilyBolder {
  font-family: @sansFontFamily;
  font-weight: 700;
  font-style: normal;
  zoom: 1;
}

.baseFontFamilyBolderItalic {
  font-family: @sansFontFamily;
  font-weight: 700;
  font-style: italic;
  zoom: 1;
}

.headingsFontFamily {
  font-family: @sansFontFamily;
  font-style: normal;
  font-weight: 400;
  zoom: 1;
}

.headingsFontFamilyItalic {
  font-family: @sansFontFamily;
  font-style: italic;
  font-weight: 400;
  zoom: 1;
}

// Component sizing
// -------------------------
// Based on 14px font-size and 20px line-height

@fontSizeLarge:         @baseFontSize * 1.20; // ~18px
@fontSizeSmall:         @baseFontSize * 0.80; // ~12px
@fontSizeMini:          @baseFontSize * 0.70; // ~11px

@paddingLarge:          11px 19px; // 44px
@paddingSmall:          2px 10px;  // 26px
@paddingMini:           0 6px;   // 22px

@baseBorderRadius:      0;//4px;
@borderRadiusLarge:     0;//6px;
@borderRadiusSmall:     0;//3px;


@fontSize11: 11px;
@fontSize12: 12px;
@fontSize13: 13px;
@fontSize15: 15px;
@fontSize17: 17px;
@fontSize19: 19px;
@fontSize21: 21px;
@fontSize23: 23px;
@fontSize25: 25px;
@fontSize27: 27px;
@fontSize29: 29px;
@fontSize31: 31px;

@baseLineHeight: 18px;
@baseFontSize: 13px;

// Tables
// -------------------------
@tableBackground:                   transparent; // overall background-color
@tableBackgroundAccent:             #f9f9f9; // for striping
@tableBackgroundHover:              #f5f5f5; // for hover
@tableBorder:                       #ddd; // table and cell border

// Buttons
// -------------------------
@btnBackground: @color1;
@btnBackgroundHighlight: @color3;
@btnBorder: @color1;
@btnColor: @color10;
@btnBackgroundHover: @color3;
@btnBackgroundHoverBorder: @color3;
@btnColorHover: @color2;

@btnPrimaryBackground:              @linkColor;
@btnPrimaryBackgroundHighlight:     spin(@btnPrimaryBackground, 20%);

@btnInfoBackground:                 #5bc0de;
@btnInfoBackgroundHighlight:        #2f96b4;

@btnInverseBackground:              #444;
@btnInverseBackgroundHighlight:     @grayDarker;

@btnSuccessBackground: @green;
@btnSuccessBackgroundHighlight: darken(@green, 5%);

@btnWarningBackground: @orange;
@btnWarningBackgroundHighlight: darken(@orange, 5%);

@btnDangerBackground: @red;
@btnDangerBackgroundHighlight: darken(@red, 5%);

@btnBlueBackground: @color3;
@btnBlueBackgroundHighlight: darken(@btnBlueBackground, 5%);
@btnBlueBackgroundActive: @blueDark;

@btnColorBackground: @color3;
@btnColorBackgroundHighlight: darken(@btnColorBackground, 5%);
@btnColorBackgroundActive: darken(@btnColorBackground, 8%);

@btnFilterBackground: @grayDark;
@btnFilterBackgroundHighlight: @grayDark;

@btnWhiteBackground: @white;
@btnWhiteBackgroundHighlight: @white;

// GRID (Argus, onyl for legacy, variable usage)
// the grid is not used
// --------------------------------------------------
@totalWidth: 1200px;
@footerHeight: 60px;
@gridSquare: 8px;
@gridSquareSprite: 40px;
@gridTableLeft: @gridSquare * 18;
@gridTableRight: @totalWidth / 2 - @gridTableLeft - @gridSquare * 8;
@clippboardWidthHeight: 175px;
//@headerHeight: 214px; -- variable CONFLICT - do not use this, we have another value

@detailContainerPadding: @gridSquare * 6;

// Forms
// -------------------------
@inputBackground: @white;
@inputBorder: @grayLight;
@inputBorderRadius: @baseBorderRadius;
@inputDisabledBackground: @grayLighter;
@formActionsBackground: #f5f5f5;
@baseFormPadding: @gridSquare / 2;
@formLabelColor: @grayLight;
@inputHeight: @baseLineHeight + 10px; // base line-height + 8px vertical padding + 2px top/bottom border

// Dropdowns
// -------------------------
@dropdownBackground:            @white;
@dropdownBorder:                rgba(0,0,0,.2);
@dropdownDividerTop:            @gray;
@dropdownDividerBottom:         @white;

@dropdownLinkColor:             @grayDark;
@dropdownLinkColorHover:        @white;
@dropdownLinkColorActive:       @white;

@dropdownLinkBackgroundActive:  @color3; // @linkColor;
@dropdownLinkBackgroundHover:   @color3; // @blueMiddle;



// COMPONENT VARIABLES
// --------------------------------------------------


// Z-index master list
// -------------------------
// Used for a bird's eye view of components dependent on the z-axis
// Try to avoid customizing these :)
@zindexDropdown:          1000;
@zindexPopover:           1010;
@zindexFixedNavbar:       1030;
@zindexModalBackdrop:     1040;
@zindexModal:             1050;
@zindexFilter:            2000;
@zindexClipboard:         3000;
@zindexTooltip:           5000;

// custom
@zIndexHeader:            2000;

// Input placeholder text color
// -------------------------
@placeholderText: @grayLight;


// Hr border color
// -------------------------
@hrBorder: @grayLighter;


// Horizontal forms & lists
// -------------------------
@horizontalComponentOffset:       180px;

// Wells
// -------------------------
@wellBackground:                  @color10; //#f5f5f5;


// Navbar
// -------------------------

@navbarCollapseWidth:             767px;//979px;
@navbarCollapseDesktopWidth:      @navbarCollapseWidth + 1;

@navbarHeight:                    40px;
// ------ Argus values are dark, this variables will never be used!
//@navbarBackgroundHighlight:       @grayDark;
//@navbarBackground:                darken(@navbarBackgroundHighlight, 5%);
//@navbarBorder:                    darken(@navbarBackground, 12%);
//
//@navbarText:                      @grayLight;
//@navbarLinkColor:                 @grayLight;
//@navbarLinkColorHover:            @white;
//@navbarLinkColorActive:           @navbarLinkColorHover;
//@navbarLinkBackgroundHover:       transparent;
//@navbarLinkBackgroundActive:      @navbarBackground;

// Original TB values for navbar
@navbarBackgroundHighlight:       @white;
@navbarBackground:                darken(@navbarBackgroundHighlight, 5%);
@navbarBorder:                    darken(@navbarBackground, 12%);

@navbarText:                      @grayLight;
@navbarLinkColor:                 @linkColor; //@grayLight;
@navbarLinkColorHover:            @color2; //@realBlueMiddle;
@navbarLinkColorActive:           @navbarLinkColorHover;
@navbarLinkBackgroundHover:       @color3; //;transparent;
@navbarLinkBackgroundActive:      @color3; //@navbarBackground

@navbarSearchBackground: lighten(@navbarBackground, 25%);
@navbarSearchBackgroundFocus: @white;
@navbarSearchBorder: darken(@navbarSearchBackground, 30%);
@navbarSearchPlaceholderColor: #ccc;
@navbarBrandColor:                @navbarLinkColor;

// Inverted navbar
@navbarInverseBackground:                #111111;
@navbarInverseBackgroundHighlight:       #222222;
@navbarInverseBorder:                    #252525;

@navbarInverseText:                      @grayLight;
@navbarInverseLinkColor:                 @grayLight;
@navbarInverseLinkColorHover:            @white;
@navbarInverseLinkColorActive:           @navbarInverseLinkColorHover;
@navbarInverseLinkBackgroundHover:       transparent;
@navbarInverseLinkBackgroundActive:      @navbarInverseBackground;

@navbarInverseSearchBackground:          lighten(@navbarInverseBackground, 25%);
@navbarInverseSearchBackgroundFocus:     @white;
@navbarInverseSearchBorder:              @navbarInverseBackground;
@navbarInverseSearchPlaceholderColor:    #ccc;

@navbarInverseBrandColor:                @navbarInverseLinkColor;


// Pagination
// -------------------------
@paginationBackground:                #fff;
@paginationBorder:                    #ddd;
@paginationActiveBackground:          #f5f5f5;

// TextHightlighting
// -------------------------
.txtHighlighting {
  background: @yellow;
  padding: 1px;
}

.tag .txtHighlighting {
  color: @yellow;
  background: none;
  padding: 0px;
  .headingsFontFamily();
}

// Hero unit
// -------------------------
@heroUnitBackground:              @grayLighter;
@heroUnitHeadingColor:            inherit;
@heroUnitLeadColor:               inherit;


// Form states and alerts
// -------------------------
// custom
@warningText: darken(@red, 20%);
@warningBackground: @red;
@warningBorder: darken((@warningBackground), 3%);

@errorText: lighten(@red, 10%);
@errorBackground: @red;
@errorBorder: darken((@errorBackground), 3%);

@successText: lighten(@green, 10%);
@successBackground: @green;
@successBorder: darken((@successBackground), 5%);

@infoText: lighten(@orange, 20%);
@infoBackground: @orange;
@infoBorder: darken((@infoBackground), 7%);

@formErrorText: @ampelRed;
@formInfoText:  @ampelGreen;
//traffic light

@trafficLightOpacityHove:1;
@navbarHeigh:1;

//@warningText: lighten(@red, 25%);
//@warningBackground: @red;
//@warningBorder: darken(spin(@warningBackground, -10), 3%);
//
//@errorText: lighten(@red, 40%);
//@errorBackground: @red;
//@errorBorder: darken(spin(@errorBackground, -10), 3%);
//
//@successText: lighten(@green, 40%);
//@successBackground: @green;
//@successBorder: darken(spin(@successBackground, -10), 5%);
//
//@infoText: lighten(@orange, 40%);
//@infoBackground: @orange;
//@infoBorder: darken(spin(@infoBackground, -10), 7%);


// Tooltips and popovers
// -------------------------
@tooltipColor:            #fff;
@tooltipBackground:       #000;
@tooltipArrowWidth:       5px;
@tooltipArrowColor:       @tooltipBackground;

@popoverBackground:       #fff;
@popoverArrowWidth:       10px;
@popoverArrowColor:       #fff;
@popoverTitleBackground:  darken(@popoverBackground, 3%);

// Special enhancement for popovers
@popoverArrowOuterWidth:  @popoverArrowWidth + 1;
@popoverArrowOuterColor:  rgba(0,0,0,.25);


// Custom width and heights
// --------------------------------------------------
@clipBoardContainerWidth:     120px;
@clipBoardContainerHeight:    120px;
@headerHeight:                @clipBoardContainerHeight + 40px; // 160
@navHeight:                   40px;
@filterContainerHeight:       @headerHeight - @navHeight; // 135
@contentHeaderHeight:         40px;
@contentContainerTopMargin:   @headerHeight + @contentHeaderHeight;

// GRID
// --------------------------------------------------


// Default 940px grid
// -------------------------
@gridColumns:             24;

@gridColumnWidth:         40px; //80px; //60px;
@gridGutterWidth:         0px; //20px;
@gridRowWidth:            (@gridColumns * @gridColumnWidth) + (@gridGutterWidth * (@gridColumns - 1));

// 1200px min
@gridColumnWidth1200:     52px;//100px; //70px;
@gridGutterWidth1200:     0px; //30px;
@gridRowWidth1200:        (@gridColumns * @gridColumnWidth1200) + (@gridGutterWidth1200 * (@gridColumns - 1));

// 768px-979px
@gridColumnWidth768:      32px;//62px; //42px;
@gridGutterWidth768:      0px; //20px;
@gridRowWidth768:         (@gridColumns * @gridColumnWidth768) + (@gridGutterWidth768 * (@gridColumns - 1));


// Fluid grid
// -------------------------
@fluidGridColumnWidth:    percentage(@gridColumnWidth/@gridRowWidth);
@fluidGridGutterWidth:    percentage(@gridGutterWidth/@gridRowWidth);

// 1200px min
@fluidGridColumnWidth1200:     percentage(@gridColumnWidth1200/@gridRowWidth1200);
@fluidGridGutterWidth1200:     percentage(@gridGutterWidth1200/@gridRowWidth1200);

// 768px-979px
@fluidGridColumnWidth768:      30px;//percentage(@gridColumnWidth768/@gridRowWidth768);
@fluidGridGutterWidth768:      percentage(@gridGutterWidth768/@gridRowWidth768);

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `@screen-xs` as of v3.0.1
@screen-xs:                  480px;
//** Deprecated `@screen-xs-min` as of v3.2.0
@screen-xs-min:              @screen-xs;
//** Deprecated `@screen-phone` as of v3.0.1
@screen-phone:               @screen-xs-min;

// Small screen / tablet
//** Deprecated `@screen-sm` as of v3.0.1
@screen-sm:                  768px;
@screen-sm-min:              @screen-sm;
//** Deprecated `@screen-tablet` as of v3.0.1
@screen-tablet:              @screen-sm-min;

// Medium screen / desktop
//** Deprecated `@screen-md` as of v3.0.1
//@screen-md:                  992px;
@screen-md:                  980px;
@screen-md-min:              @screen-md;
//** Deprecated `@screen-desktop` as of v3.0.1
@screen-desktop:             @screen-md-min;

// Large screen / wide desktop
//** Deprecated `@screen-lg` as of v3.0.1
@screen-lg:                  1200px;
@screen-lg-min:              @screen-lg;
//** Deprecated `@screen-lg-desktop` as of v3.0.1
@screen-lg-desktop:          @screen-lg-min;

// So media queries don't overlap when required, provide a maximum
@screen-under-xs:            (@screen-xs-min - 1);
@screen-xs-max:              (@screen-sm-min - 1);
@screen-sm-max:              (@screen-md-min - 1);
@screen-md-max:              (@screen-lg-min - 1);

//screen sizes

@screenSizeS-min: 768px;
@screenSizeM-min: 980px;
@screenSizeL-min: 1200px;
@screenSizeS-max: (@screenSizeS-min - 1);
@screenSizeM-max: (@screenSizeM-min - 1);
@screenSizeL-max: (@screenSizeL-min - 1);