//positiv
.icon-tonalitaet-1,
.icon-arrow-up-3{
  color: @tonality1 !important;
}
//tendenziell positiv
.icon-arrow-up-right-3, .icon-arrow-up-right,
.icon-tonalitaet-2{
  color: @tonality2 !important;
}
//ambivalent
.icon-arrow-right-3, .icon-arrow-right,
.icon-tonalitaet-3{
  color: @tonality3 !important;
}
//unbewertet
.icon-tonalitaet-4{
  color: @tonalityUnbewertet !important;
}
//tendenziell negativ
.icon-arrow-down-right-3, .icon-arrow-down-right,
.icon-tonalitaet-5{
  color: @tonality4 !important;
}
//negativ
.icon-arrow-down-3, .icon-arrow-down,
.icon-tonalitaet-6{
  color: @tonality5 !important;
}

//positiv
.icon-tonalitaet-1:before{
  content: "\e6c0";
}

//tendentiell positiv
.icon-tonalitaet-2:before {
  content: "\e6c1";
}

//neutral/ambivalent/unbewertet
.icon-tonalitaet-3:before,
.icon-tonalitaet-4:before{
  content: "\e6c2";
}

//tendentiell negativ
.icon-tonalitaet-5:before  {
  content: "\e6c3";
}

//negativ
.icon-tonalitaet-6:before {
  content: "\e6c4";
}

//positiv
.icon-arrow-up-3,
.icon-tonality-1,
.icon-tonality-6,
.icon-tonality-11,
.icon-tonality-31,
.icon-tonality-41,
.icon-tonality-51,
.icon-tonality-61,
.icon-tonality-71,
.icon-tonality-72,
.icon-tonality-81,
.icon-tonality-91,
.icon-tonality-101,
.icon-tonality-102,
.icon-tonality-111,
.icon-tonality-112,
.icon-tonality-116,
.icon-tonality-122,
.icon-tonality-211,
.icon-tonality-218,
.icon-tonality-200 {
  color: @tonality1 !important;
}

//tendenziell posititv
.icon-arrow-up-right-3, .icon-arrow-up-right,
.icon-tonality-52,
.icon-tonality-62,
.icon-tonality-73,
.icon-tonality-92,
.icon-tonality-103,
.icon-tonality-113,
.icon-tonality-201,
.icon-tonality-212 {
  color: @tonality2 !important;
}

//ambivalent
.icon-arrow-right-3, .icon-arrow-right,
.icon-tonality-3,
.icon-tonality-33,
.icon-tonality-53,
.icon-tonality-70,
.icon-tonality-74,
.icon-tonality-83,
.icon-tonality-104,
.icon-tonality-114,
.icon-tonality-123,
.icon-tonality-213,
.icon-tonality-214 {
  color: @tonality3 !important;
}

//unbewertet
.icon-tonality-0,
.icon-tonality-2,
.icon-tonality-5,
.icon-tonality-12,
.icon-tonality-20,
.icon-tonality-21,
.icon-tonality-32,
.icon-tonality-50,
.icon-tonality-90,
.icon-tonality-117,
.icon-tonality-121,
.icon-tonality-199,
.icon-tonality-202,
.icon-tonality-210,
.icon-tonality-217,
.icon-tonality-219,
.icon-tonality-221,
.icon-tonality-222{
  color: @tonalityUnbewertet !important;
}

//tendenziell negativ
.icon-arrow-down-right-3, .icon-arrow-down-right,
.icon-tonality-54,
.icon-tonality-64,
.icon-tonality-75,
.icon-tonality-94,
.icon-tonality-203,
.icon-tonality-215 {
  color: @tonality4 !important;
}

//negativ
.icon-arrow-down-3, .icon-arrow-down,
.icon-tonality-4,
.icon-tonality-7,
.icon-tonality-13,
.icon-tonality-34,
.icon-tonality-45,
.icon-tonality-55,
.icon-tonality-65,
.icon-tonality-76,
.icon-tonality-77,
.icon-tonality-85,
.icon-tonality-95,
.icon-tonality-105,
.icon-tonality-115,
.icon-tonality-118,
.icon-tonality-124,
.icon-tonality-204,
.icon-tonality-216,
.icon-tonality-220{
  color: @tonality5 !important;
}

.icon-tonality_db_big {
  font-size: 80px;
}

.icon-tonality_db_small {
  color: @tonalityUnbewertet !important;
  font-size: 40px;
}

.icon-tonality_small {
  font-size: 13px;
}

.icon-tonality_gray {
  color: @tonalityUnbewertet !important;
}

.icon-tonality-199, .icon-tonality-0,
.icon-tonality-20, .icon-tonality-21,
.icon-tonality-31, .icon-tonality-32, .icon-tonality-33, .icon-tonality-34,
.icon-tonality-1, .icon-tonality-2, .icon-tonality-3, .icon-tonality-4,
.icon-tonality-50, .icon-tonality-51, .icon-tonality-52, .icon-tonality-53, .icon-tonality-54, .icon-tonality-55,
.icon-tonality-70, .icon-tonality-71, .icon-tonality-72, .icon-tonality-73, .icon-tonality-74, .icon-tonality-75, .icon-tonality-76, .icon-tonality-77,
.icon-tonality-81, .icon-tonality-83, .icon-tonality-85,
.icon-tonality-90, .icon-tonality-91, .icon-tonality-92, .icon-tonality-94, .icon-tonality-95,
.icon-tonality-101, .icon-tonality-102, .icon-tonality-103, .icon-tonality-104, .icon-tonality-105,
.icon-tonality-111, .icon-tonality-112, .icon-tonality-113, .icon-tonality-114, .icon-tonality-115,
.icon-tonality-61, .icon-tonality-62, .icon-tonality-64, .icon-tonality-65,
.icon-tonality-116, .icon-tonality-117, .icon-tonality-118,
.icon-tonality-200, .icon-tonality-201, .icon-tonality-202, .icon-tonality-203, .icon-tonality-204,
.icon-tonality-41, .icon-tonality-45,
.icon-tonality-210, .icon-tonality-211, .icon-tonality-212, .icon-tonality-213, .icon-tonality-214, .icon-tonality-215, .icon-tonality-216,
.icon-tonality-217, .icon-tonality-218, .icon-tonality-219, .icon-tonality-220,
.icon-tonality-11, .icon-tonality-12, .icon-tonality-13,
.icon-tonality-121, .icon-tonality-122, .icon-tonality-123,.icon-tonality-124,
.icon-tonality-5, .icon-tonality-6, .icon-tonality-7,
.icon-tonality-221, .icon-tonality-222,

.icon-tonalitaet-1,.icon-tonalitaet-2,.icon-tonalitaet-3,.icon-tonalitaet-4,.icon-tonalitaet-5,.icon-tonalitaet-6
{
  font-family: 'font-icomoon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

//positiv
.icon-tonality-1:before,
.icon-tonality-6:before,
.icon-tonality-11:before,
.icon-tonality-31:before,
.icon-tonality-41:before,
.icon-tonality-51:before,
.icon-tonality-61:before,
.icon-tonality-71:before,
.icon-tonality-72:before,
.icon-tonality-81:before,
.icon-tonality-91:before,
.icon-tonality-101:before,
.icon-tonality-102:before,
.icon-tonality-111:before,
.icon-tonality-112:before,
.icon-tonality-116:before,
.icon-tonality-122:before,
.icon-tonality-200:before,
.icon-tonality-211:before,
.icon-tonality-218:before{
  content: "\e6c0";
}

//tendentiell positiv
.icon-tonality-52:before,
.icon-tonality-62:before,
.icon-tonality-73:before,
.icon-tonality-92:before,
.icon-tonality-103:before,
.icon-tonality-113:before,
.icon-tonality-201:before,
.icon-tonality-212:before {
  content: "\e6c1";
}

//neutral/ambivalent
.icon-tonality-2:before,
.icon-tonality-3:before,
.icon-tonality-5:before,
.icon-tonality-12:before,
.icon-tonality-32:before,
.icon-tonality-33:before,
.icon-tonality-53:before,
.icon-tonality-50:before,
.icon-tonality-70:before,
.icon-tonality-74:before,
.icon-tonality-83:before,
.icon-tonality-90:before,
.icon-tonality-104:before,
.icon-tonality-114:before,
.icon-tonality-117:before,
.icon-tonality-121:before,
.icon-tonality-123:before,
.icon-tonality-202:before,
.icon-tonality-210:before,
.icon-tonality-213:before,
.icon-tonality-214:before,
.icon-tonality-217:before,
.icon-tonality-219:before,
.icon-tonality-221:before,
.icon-tonality-222:before {
  content: "\e6c2";
}

//tendentiell negativ
.icon-tonality-54:before,
.icon-tonality-64:before,
.icon-tonality-75:before,
.icon-tonality-94:before,
.icon-tonality-203:before,
.icon-tonality-215:before  {
  content: "\e6c3";
}

//negativ
.icon-tonality-4:before,
.icon-tonality-7:before,
.icon-tonality-13:before,
.icon-tonality-34:before,
.icon-tonality-45:before,
.icon-tonality-55:before,
.icon-tonality-65:before,
.icon-tonality-76:before,
.icon-tonality-77:before,
.icon-tonality-85:before,
.icon-tonality-95:before,
.icon-tonality-105:before,
.icon-tonality-115:before,
.icon-tonality-118:before,
.icon-tonality-124:before,
.icon-tonality-204:before,
.icon-tonality-216:before,
.icon-tonality-220:before {
  content: "\e6c4";
}

//unbewertet
.icon-tonality-0:before,
.icon-tonality-20:before,
.icon-tonality-21:before,
.icon-tonality-199:before {
  content: "\e6c2";
}