/* ########################################################################## */
/* pandora.less                                                               */
/* ########################################################################## */
@import "variables.less";
@import "mixins.less";
@import "tile-grid.less";
@import "pandora-work.less";

a, p, li, ol, h1, h2, h3, h4, h5, h6, label {
  // smoothing
  -webkit-font-smoothing: antialiased;
}

.form_errors {
  color: @formErrorText !important;
}

.form_info {
  color: @formInfoText !important;
}

nav {
  height: @navbarHeigh;
}

input[type="radio"], input[type="checkbox"] {
  margin: 2px 0 0;
}

#basicContainer .row-fluid {
  div[id^="filterContent"], #filterPattern, #contentFilter div[id^="filterContent"] {
    padding-right: 160px;
  }
}

.noPointer {
  cursor: default !important;
}

#fixedHeader {
  max-width: 960px; // make header responsive
  min-height: 160px;
  position: fixed;
  height: auto !important; // min-heigt for IE
  height: 160px; // min-heigt for IE
  background-color: #ffffff; // don't let the left content shines trough
  z-index: @zIndexHeader;
  .shadow(gray);
}

#contentContainer {
  padding-top: 1px;
}

#fixedHeaderInner {
  height: 200px;
}

#contentHeader {
  height: @contentHeaderHeight;
  display: block;
  padding-top: 0;
  background: #ffffff;

  .btn {
    margin: 6px 0 6px 18px;
  }
}

#contentFilter {
  min-height: 120px;
  height: auto !important; // min-heigt for IE
  height: 120px; // min-heigt for IE
}

#contentHeader,
#rightContent {
  .btn {
    background-color: @btnBackground;
    border-color: @btnBorder;
    color: @btnColor;

    &:hover, &:active {
      background-color: @btnBackgroundHover;
      border-color: @btnBackgroundHoverBorder;
      color: @btnColorHover;
    }
  }
}

.actionsMenue,
#taglistContent,
#auftrag_statistics {
  padding: 16px;
}

#clipBoardContainer {
  background-color: @clipBoardBkColor; // @blueMiddle;
  height: @clipBoardContainerHeight;
  position: absolute;
  z-index: @zindexClipboard;
  top: 40px;
  right: 0;
  width: @clipBoardContainerWidth !important;
}

.checkboxWrap .checkbox {
  vertical-align: top;
  padding-left: 0;
  font-size: 1.6em;
  color: @black;

  .icon-checkbox-checked,
  .icon-checkbox-unchecked {
    background-clip: content-box;
    background-color: @white;
  }
}

.pnTrafficLight {
  @trafficLightSize: 16px;

  padding: 1px 1px 1px 3px;
  margin-top: 4px;
  font-size: @trafficLightSize - 4;
  // border: 1px solid rgba(200, 200, 200,0.3);
  // background-color: rgba(200,200,200,0.1);

  .state {
    opacity: 1;
    transition: opacity 0.4s;
    height: @trafficLightSize - 2;
    width: @trafficLightSize - 2;
    text-align: center;
    line-height: @trafficLightSize - 2;
    cursor: pointer;

    color: darken(@grayLighter, 10%);
    text-shadow: 0 0 1px darken(@grayLight, 50%);

    &.active, &:hover, &.loading {
      &.red {
        color: @ampelRed;
        text-shadow: 0 0 1px darken(@ampelRedShade, 30%);
      }
      &.green {
        color: @ampelGreen;
        text-shadow: 0 0 1px darken(@ampelGreenShade, 30%);
      }
      &.yellow {
        color: @ampelYellow;
        text-shadow: 0 0 1px darken(@ampelYellowShade, 30%);
      }
    }

    &.active {
      cursor: default;
    }

    &.disabled {
      cursor: default;
    }
  }
}

/* choice icons for release */
.releaseChoiceRed,
a i.releaseChoiceRed {
  color: @ampelRed;
}
.releaseChoiceGreen,
a i.releaseChoiceGreen {
  color: @ampelGreen;
}
.releaseChoiceYellow,
a i.releaseChoiceYellow {
  color: @ampelYellow;
}

.spinner {
  position: absolute;
  margin: 30px;
}

a .icon-file-pdf {
  color: @baseTextColor;
  &:hover {
    color: @ausschnittRed100;
  }
}

a .icon-file-xml {
  color: @baseTextColor;
  &:hover {
    color: @ausschnittLightBlue70;
  }
}

.iconExportNav {
  font-size: 1.4em;
}

.icon-facebook, .icon-facebook-2 {
  //color: @facebookBlue;
}

.icon-twitter, .icon-twitter-2 {
  //color: @twitterBlue;
}

.icon-file-word, .icon-doc-word, .icon-docart-25, .icon-docart-26, .icon-docart-83, .icon-docart-126 {
  //color: @wordBlue;
}

.icon-file-excel, .icon-doc-excel, .icon-docart-82 {
  //color: @excelGreen;
}

.icon-file-pdf, .icon-doc-pdf /*.icon-docart-10,*/ {
  color: @baseTextColor;
  &:hover {
    color: @ausschnittRed100;
  }
}

.alertMsg {
  color: #ff0000 !important;
  //   color: @alertColor !important;
}

/* Header ################################################################### */
.dropdown-toggle a {
  color: @textColor;
}

.dropdown-toggle a:hover {
  color: @color9;
  > i {
    color: @color9;
  }
}

.icon-menu {
  color: #DEDEDE;
}

.icon-menu:hover {
  color: @color9;
}

#sort-header-div {
  text-align: left;
}

#sort-header-div a {
  color: @textColor;
}

#sort-header-div a:hover {
  color: @color9;
  > i {
    color: @color9;
  }
}

@media screen and (max-width: @screen-md-max) {
  #clMenu {
    width: fit-content;
    width: -moz-fit-content;
  }
}

@media screen and (min-width: @screen-md-max){
  #clMenu {
    width: 50%;
  }
}

#clMenu {
  padding-right:6px;
  .box-sizing(border-box);
  height: 100%;
  padding: 12px 12px;

  > div {
    display: inline-block;
    margin-right: 20px;

    > .dropdown-toggle, > .nav, > a[ng-click] {
      display: inline-block;
      margin: 0;
    }
  }

  > div > a[ng-click] {
    width: 26px;
    text-align: right;

    [class^="iconImgMnu-"] {
      display: inline-block;
      vertical-align: middle;
      width: 8px;
      height: 16px;
      top: 0;
      left: 0;
    }

  }
  .dropdown-toggle a {
    text-decoration: none;
    color: @color9;

    > i {
      color: @color8;
    }
  }
  .tile-toggle-icon {
    margin-right: 0;
    font-size: 1.3em;
  }
  .tile-toggle-icon-2 {
    margin-right: 5px;
    margin-top: 2px;
  }
}

#pspListMenue {
  .dropdown-toggle a {
    text-decoration: none;
    color: @color9;

    > i {
      color: @color8;
    }
  }
}


#clMenu i {
  //not for complete contentHeader -> dashboard needs other size
  font-size: 1.4em;
  vertical-align: -2px;
}

.menuItemSelected {
  background-color: @elementSelectedBgColor;
  color: @elementSelectedColor !important;
  i {
    color: @elementSelectedColor !important;
  }
}

span.menuItem {
  padding-left: 6px;
  padding-right: 6px;
}

span.menuItemNarrow {
  padding-left: 1px;
  padding-right: 1px;
  display: inline-block;
}

.headerLeft {
  padding-right: 160px; // make space for .clipBoardContainer
  height: 40px;
}

/* Clipping list ############################################################ */

.clipping_list_page {
  .minHeigth {
    min-height: 90px;
  }
  .contentElementCellLeft.floatList {
    float: none;
  }

  .clippingListeContent article:last-child {
    border-bottom: none;
  }
}

// Clases to switch clipping list display mode
.clippingListeContent {
  padding-bottom: 50px;
}

.contentShortClippinglist {
  display: none;
}

.contentDefaultClippinglist {
  display: inline;
}

.contentDefaultClippinglist.bold {
  font-weight: bold;
}

.contentBold, .contentBold * {
  font-weight: bold;
}

.contentDefaultClippinglist.bold-head {
  font-weight: bold;
  font-size: 1.2em;
}

.contentElementTabSmall {
  display: table;
}

.contentElementTab {
  width: 100%;
  display: table;
}

.contentElementRow {
  display: table-row;
}

.contentElementCellLeft {
  text-align: left;
  display: table-cell;
  padding-right: 4px;
  vertical-align: top;
  float: left;
  //  vertical-align: middle;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &.headlineWrapper {
    width: 500px;
  }

  @media (max-width: @screenSizeL-max) {
    &.headlineWrapper {
      width: 360px;
    }
  }

}
.moveTopRow {
  padding-top: 10px;
}
.contentTabLeft {
  text-align: left;
  display: table-cell;
  padding-right: 4px;
  vertical-align: top;
}

.contentElementCellRight {
  text-align: right;
  display: table-cell;
  padding-left: 4px;
  vertical-align: bottom;
  // vertical-align: middle;
  min-width: 60px;
}

.contentNowrap {
  white-space: nowrap;
}

.contentIconElement {
  right: 16px;
  top: 16px; //font-size: 1.4em;
  font-size: 20px;
}

.content_ICONS {
  right: 16px;
  top: 16px; //font-size: 1.4em;
  font-size: 20px;
  white-space: nowrap;
  float: right;
}

.content_icon {
  display: table-cell;
  width: 26px;
  height: 26px;
  white-space: nowrap;
  vertical-align: middle;
  text-align: left;

  > a, > span {
    display: table-cell;
    text-align: center;
    //    vertical-align: left;
  }

  .article-icon {
    top: 0;
    left: 0;
  }

}

.content_icon_left {
  float: left;
  width: 27px;
  white-space: nowrap;
  vertical-align: middle;
}

.content_iconSmall {
  // float: left;
  display: inline-block;
  width: 20px;
  white-space: nowrap;
  vertical-align: 0;

  a > .icon_small {
    color: @color9;
    font-size: 14px;
  }
  a:hover > .icon_small {
    color: darken(@color9, 10%);
  }
}

tr.content_iconRow {
  vertical-align: top;
}

.content_HEADLINE {
  font-weight: normal;
  font-size: 1.3em !important;
  word-break: break-word;

  &.converted-feed-content {
    a, a:hover, a:active {
      color: #80bade !important;
    }
  }

  > .content_ShareIcons {
    display: inline-block;
  }
  > a {
    margin-right: 6px;
  }
  a {
    color: @color9 !important;
  }
  a:hover, a:active {
    color: @color9 !important;
  }

}

.content_MEDIENTITEL {
  // font-weight: bold;
}

.content_DATUM {
  font-weight: normal;

  &.converted-feed-content {
    color: #adadad !important;
  }
}

.converted-feed-content {
  color: #adadad !important;
}

.content_AddDataTitle {
  white-space: nowrap;
}

.content_AddDataValue {
  width: 100%;
}

// flag to diplay medium country code
img.flag {
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
}

#paginationFooterWraper {
  display: block;
  position: fixed;
  //  left: 110;
  bottom: 0;
  background-color: @white;
  z-index: 0; //10;
}

.paginationFooter:before, .paginationFooter:after {
  content: "";
  display: table;
}

.paginationFooter {
  background: none repeat scroll 0 0 white;
  height: 100%;
  list-style: none outside none;
  margin: 0;
  padding-bottom: 5px;
  padding-top: 16px;
}

// Icon sizes
[class^=icon-docart], [class^=icon-medienart], [class^=icon-tonality] {
  //font-size: 1.4em;
  font-size: 20px;
}

.tooltip {
  z-index: @zindexTooltip !important;
}

/*
article.viewList {
  padding-right: 18px !important;
}
*/

ul.elementsFix {
  margin-bottom: 10px !important;
}

.viewList {
  .contentElementWrap {
    margin-right: 0 !important;
    margin-bottom: 0;
  }

  .contentElementCellLeft > a {
    vertical-align: middle;
  }
  .contentElementCellRight > a > i {
    font-size: 17px;
  }
  // &.show
}

.noDataMessage {
  font-size: 20px;
  font-weight: bolder;
  padding-top: 120px;
  text-align: center;
  vertical-align: bottom;
  color: @color3;
}

/* Actions for selected articles ############################################ */

.actionsMenue {
  background: @selArticleBkColor; // @grayLighter; /*#f0f0f0;*/
}

#selectedArticleList {
  background: @selArticleBkColor !important;
  border: medium none;
  display: block;
  overflow: hidden;
  padding: 1em 0.2em !important;
  .box-sizing(border-box);

  .selectedArticleItem:nth-child(even){
    background: @white !important;
  }
}

#selectedItemsList {
  background: @selArticleBkColor !important;
  border: medium none;
  display: block;
  overflow: hidden;
  padding: 1em 0.2em !important;
  .box-sizing(border-box);
}

.selectedArticleMenueTable {
  display: table;
  color: @clDetailColor; // @grayDark; /*#3F4854;*/ //  font-family: "CentraleSansRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.selectedArticleMenueTable div {
  display: table-row;
}

.selectedArticleMenueTable div div {
  display: table-cell;
  padding-bottom: 1px;
  padding-top: 1px;
  padding-left: 1px;
  padding-right: 1px;
}

.selArtField {
  .box-sizing(border-box);
  width: 100%;
  margin: 0 !important;

  &[type="text"] {
    height: auto !important;
  }
}

.radioSmall,
.checkboxSmall {
  min-height: @baseLineHeight; // clear the floating input if there is no label text
  padding: 0;
  color: @black;
}

// selectedArticleMenue, #selArtMailForm {
.actionsMenue .btn-selArt,
.actionsMenue .btn,
#chooseConfig .btn,
#PspCreateContentConfigRight .btn:not(.simpleeditor-btn),
#leftContent .btn {
  width: auto;
  margin: 0;
  padding: 4px 8px;
  text-align: left;
  background-color: @btnBackground !important;
  border-color: @btnBorder;
  color: @btnColor;
  &.btn-inline {
    margin: 0 8px 8px 0;
  }

  &:hover, &:active {
    border-color: @btnBackgroundHoverBorder !important;
    background-color: @btnBackgroundHover !important;
    color: @btnColorHover;
  }

  &.btn-del {
    &:hover, &:active {
      border-color: @red !important;
      background-color: @red !important;
    }
  }

  &.blue {
    background-color: @btnBackgroundHighlight !important;
    border-color: @btnBackgroundHoverBorder;
    color: @btnColorHover;
  }

  > i {
    font-size: 13px;
    padding: 0;
    margin-right: 6px;
  }

}

.actionsMenue .btn.mraButtonDisabled, .actionButtonDisabled {
  border-color: @btnBorder !important;
  background-color: @btnBackground !important;
  color: @btnColor;
  .opacity(50);
  cursor: default;

  &:hover, &:active {
    border-color: @btnBorder !important;
    background-color: @btnBackground !important;
    color: @btnColor;
    .opacity(50);
  }
}

#selectedArticleMailForm #SelArticleMail_coverLetter {
  resize: vertical !important;
}

.selArtBtnCell {
  padding: 0 8px 8px 0;
  // padding: 0;
}

.clippingDetailAccordion {
  padding-top: 8px;
}

.row-fluid:first-child .header-selArt {
  padding-top: 0;
  margin-top: 0;
  border-top: none;
}

.selArtBtnList {
  padding-bottom: 0;
}

hr {
  margin: 8px 0 16px;
  border-top: 1px solid @grayLighterBorder;
  border-bottom: none;
}

#selectedArticleMenue .rowFirst {
  margin-left: 4px;
}

.rowLast {
  margin-right: 8px;
}

.formStatusMessage {
  margin-bottom: 8px;

  > div {
    min-height: 0 !important;
  }
  .formStatusTitle {
    font-weight: bold;
  }
}

.actionsMenue .clippingDetailAccordion {
  padding-top: 8px;
}

.btn-high-9px {
  margin-bottom: 9px;
}

.bold-headline {
  border-top: 1px solid darken(@color7, 15%);
  font-size: 1.2em;
  font-weight: bold;
  padding: 16px 0 16px 3px;
  text-align: left;
  margin: 3px 0 0 0;
  .box-sizing(border-box);
}

.action-top-headline {
  font-size: 1.2em;
  font-weight: bold;
  padding: 5px 0 8px 3px;
  text-align: left;
  margin: 0 0 0 0;
  .box-sizing(border-box);
}

.action-middle-headline {
  border-top: 1px solid darken(@color7, 15%);
  font-size: 1.1em;
  font-weight: bold;
  padding: 5px 0 5px 3px;
  text-align: left;
  margin: 0 0 0 0;
  .box-sizing(border-box);
}

.without-border {
  border: none !important;
}

.actionsMenue .row-fluid:first-child .bold-headline {
  padding-top: 0;
  margin-top: 0;
  border-top: none;
}

.actionsMenue .selArtBtnList {
  padding-bottom: 0;
}

.rowSmall {
  min-height: 0 !important;
}

.actionsMenue hr {
  margin: 8px 0 16px;
  border-top: 1px solid @grayLighterBorder;
  border-bottom: none;
}

.actionsMenue .rowFirst {
  margin-left: 4px;
}

.actionsMenue .rowLast {
  margin-right: 8px;
}

.actionsMenue .formStatusMessage {
  margin-bottom: 8px;

  > div {
    min-height: 0 !important;
  }
  .formStatusTitle {
    font-weight: bold;
  }
}

/* ClipBoard ################################################################ */

.contentClipBoard {
  vertical-align: middle;
  color: @clipBoardColor; // @white;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

#clipBoardTitle {
  font-size: 15px;
  font-weight: bolder;
  text-align: center;
  vertical-align: middle;
}

#clipBoardCounter {
  font-size: 45px;
  font-weight: bolder;
  text-align: center;
  vertical-align: middle;
  padding-top: 12px;
  height: 27px;
}

#clipBoardAction {
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  padding: 5px;
}

/* Administration ########################################################### */
.bold-headline {
  // font-weight: bold;
  // font-size: 1.8em;
}

.bold-error {
  text-align: center;
  color: @blueDark;
  font-weight: bold;
  font-size: 1.4em;
  padding-top: 60px;
  margin: 5px;
}

.baseColor1Background {
  background: @mainBkColor !important
}

.baseColor1Foreground {
  color: @mainBkColor !important;
}

#login-form-warning {
  width: 100%;
  padding: 19px 29px 29px;
  margin: 0 auto 20px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
}

#login-form {
  max-width: 600px;
  margin: 0 auto;

  @media screen and (max-width: 600px) and (max-height: 600px) {
    padding: 0;
  }


}

.login-form-item {
  width: 100%;
  padding: 10px;
  margin: 5px 0 5px -10px;;
  background-color: @usrAdmHeadlineBkColor;
  border: 1px solid #e5e5e5;
}

/* Datepicker */
.datepicker .icon-arrow-right,
.datepicker .icon-arrow-left {
  color: @textColor !important; /*überschreibt Tonalitätsfarbe*/
}

.datepicker {
  border-radius: 0;
}

/* Timepicker */
.timepicker {
}

.bootstrap-timepicker-widget.dropdown-menu {
  &:before, &:after {
    content: none;
  }
  min-width: 120px;

  a {
    padding: 4px 0;
  }
}

/* Tags*/
.tag-arrow-left {
  float: left;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-right: 13px solid @tagColor;
  height: 0;
  width: 0;
}

.tag-content {
  float: left;
  color: #ffffff;
  background-color: @tagColor;
  padding-left: 1px;
  padding-right: 10px;
  height: 26px;
  line-height: 26px;
  margin-right: 10px;
}

.tag.ak {
  margin-bottom: 0 !important;
  font-size: 10px !important;
  height: 12px !important;
  &:before {
    border-top-width: 7px !important;
  }
}

.coverBgImage {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: 0;

  > img {
    max-width: none;
    -ms-interpolation-mode: bicubic;
  }
}

/* Sidebar */
.sidebar-toggle-button {
  display: none;
  width: 40px;
  height: 100%;
  background: @detailsBackground url('/build/img/icon-arrow-big-left.png') no-repeat 10px 30px;
  position: absolute;
  top: 0;
  left: -40px;
  cursor: pointer;
  &.open {
    background-image: url('/build/img/icon-arrow-big-right.png');
  }
}

/* Content menu */
#content_menue {
  height: @contentHeaderHeight;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  .contentHeaderVCentered {
    display: table;
    width: auto;
    height: 100%;
    float: left;

    h2, .h2 {
      padding: 0 8px 0 20px;
    }
    h2, .h2, a {
      display: table-cell;
      vertical-align: middle;
      margin: 0;
      padding: 0 8px 0 8px;
      color: @color3;
    }
  }

  h2, .h2 {
    font-weight: 700;
    color: @linkColor;
  }

  h2 + a {
    > i {
      display: table-cell;
      font-size: 20px;
      color: @color3;
    }
    &:hover > i {
      color: @linkColorHover;
    }
  }

  .menu {
    &.menu-right {
      .box-sizing(border-box);
      float: right;
      height: 100%;
      padding: 12px 12px;
    }

    .menu-button {
      color: @color6;
      font-size: 17px;
      font-style: normal;
      font-weight: bold;
    }
    .icon-menu-2 {
      font-size: 16px;
    }

    li i {
      display: inline-block;
      width: 13px;
    }
  }
}

/* Taglist */
#taglistContent {
  > div {
    width: 100%;
  }

  > .contentBold {
    padding: 4px 0;
  }

  input.adjacentBtn {
    border-right-width: 0;
  }

  .row-fluid, input, button {
    height: 100%;
    margin: 0;
    .box-sizing(border-box);
  }

  .taglistUnit {
    margin: 16px 0 8px;
  }
  .tags {
    margin: 0;
  }
  .taglistSpacing {
    margin: 0 0 8px;
  }
  .taglistRow {
    min-height: 40px;
    padding-top: 11px;
    border-top: 1px solid @grayLighterBorder;
  }
  .taglistRow:first-child {
    border: none;
  }

  .tagAdminEdit {
    height: 28px;
    margin-top: -5px;

    > div {
      &.tagAdminCancel {
        padding-left: 4px;
      }

      input {
        width: 100%;
        margin: 0;
      }
    }
  }
  .linkPadding {
    padding: 0 10px 0 10px;
  }
}

div#clMenuOptions {
  position: relative;
  float: right;
  color: @color9;
  font-style: normal;
  margin-top: 7px;

  span {
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
  }

  .dropdown-menu .divider {
    padding: 0 !important;
  }

  .dropdown-menu {
    //    max-height: 450px !important;
    overflow: visible;
  }

  .dropdown-submenu.pull-left > .dropdown-menu {
    max-width: 200px;
    left: -190px !important;
    margin-left: 0  !important;
  }

  a.dropdown-toggle {
    text-decoration: none;
  }

  i.clMenu {
    margin-right: 10px;
  }

}

div#clMenuDropdown {
  margin-right: 20px;
  margin-top: 5px;
}

#nav_beta {
  color: @color3;
  float: left;
  font-size: 2em;
  padding-bottom: 11px;
  padding-left: 15px;
  padding-right: 40px;
  padding-top: 11px;
  text-decoration: none;
}

#msp_error {
  .modal-footer {
    padding: 10px;
  }
  button {
    width: 85px;
    height: 30px;
  }
}

/* LighboxModals */

.modalTextOnlyContainer {
  margin: 10px;
}

/*modal medienArtTypTree*/
.modalHeader {
  background-color: @modalHeaderBkColor;
  color: @modalHeaderColor;
  font-size: 28px;
  margin: 0;
  padding: 8px 16px;
  font-weight: 700;
}

.modalContainer {
  float: left;
  background-color: @bodyBackground;
  border: none;
  display: block;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  width: 492px;
  min-height: 250px;

  .box-sizing(border-box);
  padding: 16px;
}

.modalBackground {
  float: left;
  background-color: @bodyBackground;
  display: block;
  border: 8px solid @filterBkColor;
  position: fixed;
  width: 492px;
}

.modalFooter {
  float: right;
  background-color: @filterBkColor;
  border: none;
  display: block;
  width: 100%;
}

#overlayFooterButtonsLeft {
  margin-top: 20px;
  float: left;
}

.overlayFooterButtons {
  margin-top: 8px;
  float: right;
}

.bkMedienart-1 {
  background-color: @medienart1;
}

.bkMedienart-2 {
  background-color: @medienart2;
}

.bkMedienart-3 {
  background-color: @medienart3;
}

.bkMedienart-4 {
  background-color: @medienart4;
}

.bkMedienart-6 {
  background-color: @medienart6;
}

.bkMedienart-7 {
  background-color: @medienart7;
}

.colorMedienart-0 {
  color: @medienart0-darker !important;
}
.colorMedienart-1 {
  color: @medienart1-darker !important;
}
.colorMedienart-2 {
  color: @medienart2-darker !important;
}
.colorMedienart-3 {
  color: @medienart3-darker !important;
}
.colorMedienart-4 {
  color: @medienart4-darker !important;
}
.colorMedienart-6 {
  color: @medienart6-darker !important;
}
.colorMedienart-7 {
  color: @medienart7-darker !important;
}

#mainNav {
  #nav_work {
    .dropdown-menu {
      max-height: 600px;
    }
  }
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

/* mouse cursor */
.cursorPointer {
  cursor: pointer;
}

/* clipping list styles */
.checkBoxListHead {
  border-bottom: 1px solid @color6;
  margin-bottom: 3px;
}

/* alert colors */
.alertTextError {
  color: @alertError;
}

.alertTextSuccess {
  color: @alertSuccess;
}

#statusMessageLine {
  display:none;
}

/* Angular */
a[ng-click] {
  cursor: pointer;
}

.errorBorder {
  border: 1px solid @alertError;
}

.loginBackground {
  background-image: url('/build/img/login_bg2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: fixed;
  //top:0;
  //bottom:0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.ubCircleBG {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -304px;
  margin-left: -300px;
  background-image: url('/build/img/circles_bg.png');
  background-repeat: no-repeat;
  width: 600px;
  height: 608px;

  @media (max-width: 767px) {
    width: 0;
    height: 0;
    background-image: none;
  }

  @media (max-height: 767px) {
    width: 0;
    height: 0;
    background-image: none;
  }

}

@media (max-width: 600px) {

  #webpageLink {
    display: none;
  }
}

.separateContentBlock {
  .clearfix;
}

.separateContentBlock +  .separateContentBlock {
  .separatorLineTop();
  padding-top:5px;
  margin-top:5px;
}

.separateContentSection + .separateContentSection {
  margin-top: 20px;
}
