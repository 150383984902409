/* work content config lists */

.workContentConfigList {

  .workContentConfigAddButton {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-top: 5px;
    border-bottom: 1px solid @color7;
  }
  .listElemTitle {
    font-weight: bold !important;
  }
  .contentElementWrap {
    margin-left: 10px !important;
  }
}

