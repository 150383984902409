.ubHeadline {
  color: #ffffff;
  font-size: 34px !important;
  font-weight: normal;
}

.ubSubHeadline {
  color: #ee8e25;
  font-size: 19px !important;
  font-weight: normal;
}


.ubHeaderBar {
  /*background-color: #f7f7f7;*/
}

.ubLogoContainer {
  width: 100%;
  text-align: center;
  padding: 40px 0;
}

.ubHintMessage {
  margin: 0 auto;
  box-sizing: border-box;
}

.ubLogo {
  border: 0;
}

.ubLogo, .ubHintMessage, .ubFooter {
  width: 460px;
}

.ubFooter {
  margin: 0px auto;
}

.ubFooterLinkRow {
  border-top: 1px solid #828282;
  padding: 10px 0;
}

.ubFooterLinkRow .ubLink {
  cursor: pointer;
}

.ubText {
  color: #ffffff;
  font-size: 14px;
}

.ubTextLink,
.ubTextLink:hover,
.ubTextLink:visited,
.ubTextLink:active {
  color: #ffffff;
  font-size: 14px;
}

.ubTextLinkSmall,
.ubTextLinkSmall:hover,
.ubTextLinkSmall:visited,
.ubTextLinkSmall:active {
  color: #ffffff;
  font-size: 12px;
  font-weight: normal;
}

.ubHeaderLink {
  padding: 0px 30px;
}

/* Forms */

.ubFormContainer {
  position: fixed;
  width: 230px;
  height: 188px;
  left: 50%;
  top: 50%;
  margin-left: -115px;
  margin-top: -94px;
}


.ubRegisterContainer{
  margin-top: 50px;

  @media (max-height: 600px) {
    margin-top: 0;
  }

  @media (max-width: 600px) {
    margin-top: 0;
  }
}

.ubRegisterFormContainer {
  margin:0 auto;
  .ubNewsletterCheckboxContainer {
    width: 30px;
    float: left;
  }
  .ubNewsletterTextContainer {
    width: 365px;
    float: left;
    padding-bottom: 20px;
    color: #ffffff;
  }
  @media (max-width: 768px) {
    width:300px;
    .ubNewsletterTextContainer {
      width: 250px;
    }


  }
}

//.ubRegisterHeadline {
//  margin-top: 100px;
//
//  @media (max-width: 600px) {
//    margin-top: 10px;
//  }
//
//  @media (max-height: 600px) {
//    margin-top: 10px;
//  }
//
//}

.ubFormInput::-webkit-input-placeholder
{
  color: #cccccc;
}

.ubFormInput::-moz-placeholder
{
  color: #aaaaaa;
}

.ubFormInput:-ms-input-placeholder
{
  color: #cccccc;
}

.ubFormInput:-moz-placeholder
{
  color: #aaaaaa;
}


input.ubFormInput {
  border: 0px solid #ffffff;
  font-size: 18px;
  line-height: 32px;
  height: auto;
}
select.ubFormSelect {
  border: 0px solid #ffffff;
  /*font-size: 18px;*/
  line-height: 32px;
  font-size: 18px;
  height: 40px;
}

.ubLinkFooter {
  position: fixed;
  bottom: 10px;
  left: 0px;
  text-align: center;
  width: 100%;

  a {
    color: #ffffff;
  }
}

.ubRegisterLinkFooter {
  text-align: center;
  width: 100%;
  margin-top: 50px;

  a {
    color: #ffffff;
  }
}

/* Buttons */

.ubButton,
.ubButton:hover,
.ubButton:visited,
.ubButton:active {
  font-size: 14px;
  text-transform: uppercase;
  color: #ee8e25;
  text-decoration: none;
  border: 1px solid #ee8e25;
  background-color: transparent;
  display: inline-block;
  padding: 5px 15px;
  box-sizing: border-box;
}

.ubButtonGrey,
.ubButtonGrey:hover,
.ubButtonGrey:visited,
.ubButtonGrey:active {
  font-size: 14px;
  text-transform: uppercase;
  color: #aaaaaa;
  text-decoration: none;
  border: 1px solid #aaaaaa;
  background-color: transparent;
  display: inline-block;
  padding: 5px 15px;
  box-sizing: border-box;
}

.aui-button {
  word-break: keep-all;
  white-space: normal;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

/* Large desktop */
@media (min-width: 1200px) {
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .ubLogoContainer {
    padding: 30px 0;
  }

  .ubLogo, .ubHintMessage, .ubFooter {
    width: 400px;
  }
}

/* Landscape phones and down */
@media (max-width: 480px) {
  .ubLogoContainer {
    padding: 20px 0;
  }

  .ubLogo, .ubHintMessage, .ubFooter {
    width: 270px;
  }
}