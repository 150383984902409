//"@{asseticPath}/webfonts";
// url('@{asseticPath}/webfonts/
// aqbmv9 --> fgk48sD

@font-face {
  font-family: 'font-icomoon';
  src: url('/build/fonts/font-icomoon-2019.eot?u3iima');
  src: url('/build/fonts/font-icomoon-2019.eot?u3iima#iefix') format('embedded-opentype'),
  url('/build/fonts/font-icomoon-2019.woff?u3iima') format('woff'),
  url('/build/fonts/font-icomoon-2019.ttf?u3iima') format('truetype'),
  url('/build/fonts/font-icomoon-2019.svg?u3iima#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'font-icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.icon-asterisk:before {
    content: "\f069";
}
.icon-plus1:before {
    content: "\f067";
}
.icon-minus1:before {
    content: "\f068";
}
.icon-search1:before {
    content: "\f002";
}
.icon-envelope-o:before {
    content: "\f003";
}
.icon-heart:before {
    content: "\f004";
}
.icon-star1:before {
    content: "\f005";
}
.icon-star-o:before {
    content: "\f006";
}
.icon-user1:before {
    content: "\f007";
}
.icon-th-large:before {
    content: "\f009";
}
.icon-th1:before {
    content: "\f00b";
}
.icon-th-list:before {
    content: "\f00c";
}
.icon-check:before {
    content: "\f00d";
}
.icon-close1:before {
    content: "\f00e";
}
.icon-remove1:before {
    content: "\f00e";
}
.icon-times:before {
    content: "\f00e";
}
.icon-cog1:before {
    content: "\f013";
}
.icon-gear:before {
    content: "\f013";
}
.icon-trash-o:before {
    content: "\f014";
}
.icon-file-o:before {
    content: "\f016";
}
.icon-clock-o:before {
    content: "\f017";
}
.icon-download1:before {
    content: "\f019";
}
.icon-inbox:before {
    content: "\f01c";
}
.icon-repeat:before {
    content: "\f01e";
}
.icon-rotate-right:before {
    content: "\f01e";
}
.icon-refresh:before {
    content: "\f021";
}
.icon-lock1:before {
    content: "\f023";
}
.icon-flag1:before {
    content: "\f024";
}
.icon-volume-up:before {
    content: "\f028";
}
.icon-tag1:before {
    content: "\f02b";
}
.icon-tags:before {
    content: "\f02c";
}
.icon-bookmark1:before {
    content: "\f02e";
}
.icon-print1:before {
    content: "\f02f";
}
.icon-video-camera:before {
    content: "\f03d";
}
.icon-image1:before {
    content: "\f03e";
}
.icon-photo:before {
    content: "\f03e";
}
.icon-picture-o:before {
    content: "\f03e";
}
.icon-pencil1:before {
    content: "\f040";
}
.icon-edit:before {
    content: "\f044";
}
.icon-pencil-square-o:before {
    content: "\f044";
}
.icon-chevron-left:before {
    content: "\f053";
}
.icon-chevron-right:before {
    content: "\f054";
}
.icon-plus-circle1:before {
    content: "\f058";
}
.icon-minus-circle1:before {
    content: "\f059";
}
.icon-times-circle:before {
    content: "\f05a";
}
.icon-check-circle:before {
    content: "\f05b";
}
.icon-question-circle:before {
    content: "\f05c";
}
.icon-info-circle:before {
    content: "\f05d";
}
.icon-exclamation-circle:before {
    content: "\f06a";
}
.icon-eye1:before {
    content: "\f06e";
}
.icon-eye-slash:before {
    content: "\f070";
}
.icon-comment:before {
    content: "\f075";
}
.icon-chevron-up1:before {
    content: "\f078";
}
.icon-chevron-down:before {
    content: "\f079";
}
.icon-folder1:before {
    content: "\f07b";
}
.icon-folder-open1:before {
    content: "\f07c";
}
.icon-bar-chart:before {
    content: "\f080";
}
.icon-bar-chart-o:before {
    content: "\f080";
}
.icon-heart-o:before {
    content: "\f08a";
}
.icon-sign-out2:before {
    content: "\f08b";
}
.icon-sign-out:before {
    content: "\f08c";
}
.icon-thumb-tack:before {
    content: "\f08d";
}
.icon-upload1:before {
    content: "\f093";
}
.icon-bell-o:before {
    content: "\f0a2";
}
.icon-wrench1:before {
    content: "\f0ad";
}
.icon-filter1:before {
    content: "\f0b0";
}
.icon-group:before {
    content: "\f0c0";
}
.icon-users1:before {
    content: "\f0c0";
}
.icon-chain:before {
    content: "\f0c1";
}
.icon-link1:before {
    content: "\f0c1";
}
.icon-copy1:before {
    content: "\f0c5";
}
.icon-files-o:before {
    content: "\f0c5";
}
.icon-floppy-o:before {
    content: "\f0c7";
}
.icon-save:before {
    content: "\f0c7";
}
.icon-magic:before {
    content: "\f0d0";
}
.icon-envelope:before {
    content: "\f0e0";
}
.icon-rotate-left:before {
    content: "\f0e2";
}
.icon-undo1:before {
    content: "\f0e2";
}
.icon-comment-o:before {
    content: "\f0e5";
}
.icon-bell1:before {
    content: "\f0f4";
}
.icon-file-text-o:before {
    content: "\f0f6";
}
.icon-desktop:before {
    content: "\f108";
}
.icon-laptop1:before {
    content: "\f109";
}
.icon-tablet1:before {
    content: "\f10a";
}
.icon-mobile1:before {
    content: "\f10b";
}
.icon-mobile-phone:before {
    content: "\f10b";
}
.icon-circle-o:before {
    content: "\f10c";
}
.icon-circle1:before {
    content: "\f112";
}
.icon-folder-o:before {
    content: "\f114";
}
.icon-folder-open-o:before {
    content: "\f115";
}
.icon-unlock-alt:before {
    content: "\f13e";
}
.icon-dot-circle-o:before {
    content: "\f192";
}
.icon-file-pdf-o:before {
    content: "\f1c1";
}
.icon-file-image-o:before {
    content: "\f1c5";
}
.icon-file-photo-o:before {
    content: "\f1c5";
}
.icon-file-picture-o:before {
    content: "\f1c5";
}
.icon-file-archive-o:before {
    content: "\f1c6";
}
.icon-file-zip-o:before {
    content: "\f1c6";
}
.icon-file-audio-o:before {
    content: "\f1c7";
}
.icon-file-sound-o:before {
    content: "\f1c7";
}
.icon-file-movie-o:before {
    content: "\f1c8";
}
.icon-file-video-o:before {
    content: "\f1c8";
}
.icon-file-code-o:before {
    content: "\f1c9";
}
.icon-history1:before {
    content: "\f1da";
}
.icon-share-alt:before {
    content: "\f1e0";
}
.icon-bell-slash:before {
    content: "\f1f6";
}
.icon-bell-slash-o:before {
    content: "\f1f7";
}
.icon-trash:before {
    content: "\f1f8";
}
.icon-area-chart:before {
    content: "\f1fe";
}
.icon-pie-chart:before {
    content: "\f200";
}
.icon-line-chart:before {
    content: "\f201";
}
.icon-toggle-off:before {
    content: "\f204";
}
.icon-toggle-on:before {
    content: "\f205";
}
.icon-user-plus:before {
    content: "\f234";
}
.icon-user-times:before {
    content: "\f235";
}
.icon-clone:before {
    content: "\f24d";
}
.icon-low-vision:before {
    content: "\f2a8";
}
.icon-user-circle:before {
    content: "\f2bd";
}
.icon-user-circle-o:before {
    content: "\f2be";
}
.icon-user-o:before {
    content: "\f2c0";
}
.icon-th:before {
    content: "\f00a";
}
.icon-plus-circle:before {
    content: "\f055";
}
.icon-minus-circle:before {
    content: "\f056";
}
.icon-remove-sign:before {
    content: "\f057";
}
.icon-chevron-up:before {
    content: "\f077";
}
.icon-arrows-v:before {
    content: "\f07d";
}
.icon-arrows-h:before {
    content: "\f07e";
}
.icon-globe-2:before {
    content: "\f0ac";
}
.icon-caret-down:before {
    content: "\f0d7";
}
.icon-caret-up:before {
    content: "\f0d8";
}
.icon-caret-left:before {
    content: "\f0d9";
}
.icon-caret-right:before {
    content: "\f0da";
}
.icon-unsorted:before {
    content: "\f0dc";
}
.icon-bell2:before {
    content: "\f0f3";
}
.icon-angle-double-left:before {
    content: "\f100";
}
.icon-angle-double-right:before {
    content: "\f101";
}
.icon-angle-double-up:before {
    content: "\f102";
}
.icon-angle-left:before {
    content: "\f104";
}
.icon-angle-right:before {
    content: "\f105";
}
.icon-angle-up:before {
    content: "\f106";
}
.icon-angle-down:before {
    content: "\f107";
}
.icon-circle:before {
    content: "\f111";
}
.icon-flag2:before {
    content: "\e153";
}
.icon-attach_file:before {
    content: "\e226";
}
.icon-error:before {
    content: "\e000";
}
.icon-error_outline:before {
    content: "\e001";
}
.icon-help:before {
    content: "\e887";
}
.icon-help_outline:before {
    content: "\e8fd";
}
.icon-lock2:before {
    content: "\e897";
}
.icon-info2:before {
    content: "\e88e";
}
.icon-info_outline:before {
    content: "\e88f";
}
.icon-live_help:before {
    content: "\e0c6";
}
.icon-mail_outline:before {
    content: "\e0e1";
}
.icon-menu2:before {
    content: "\e5d2";
}
.icon-mode_comment:before {
    content: "\e253";
}
.icon-more:before {
    content: "\e729";
}
.icon-notifications:before {
    content: "\e7f4";
}
.icon-playlist_play:before {
    content: "\e05f";
}
.icon-reorder:before {
    content: "\e8fe";
}
.icon-reply2:before {
    content: "\e15e";
}
.icon-report:before {
    content: "\e160";
}
.icon-warning2:before {
    content: "\e002";
}
.icon-translate:before {
    content: "\e8e2";
}
.icon-view_headline:before {
    content: "\e8ee";
}
.icon-align-justify2:before {
    content: "\e026";
}
.icon-align-left:before {
    content: "\e027";
}
.icon-align-center:before {
    content: "\e028";
}
.icon-align-right:before {
    content: "\e029";
}
.icon-mail-4:before {
    content: "\e728";
}
.icon-th-small:before {
    content: "\e710";
}
.icon-th-menu:before {
    content: "\e711";
}
.icon-align-justify:before {
    content: "\e70b";
}
.icon-reload:before {
    content: "\e901";
}
.icon-chat:before {
    content: "\e70c";
}
.icon-book:before {
    content: "\e70d";
}
.icon-newspaper-2:before {
    content: "\e70e";
}
.icon-health:before {
    content: "\e70f";
}
.icon-issue-opened:before {
    content: "\f026";
}
.icon-list-ordered:before {
    content: "\f062";
}
.icon-list-unordered:before {
    content: "\f061";
}
.icon-mail2:before {
    content: "\f03b";
}
.icon-mail-reply:before {
    content: "\f051";
}
.icon-three-bars:before {
    content: "\f05e";
}
.icon-tools:before {
    content: "\f031";
}
.icon-home:before {
    content: "\e607";
}
.icon-home-2:before {
    content: "\e608";
}
.icon-newspaper:before {
    content: "\e609";
}
.icon-pencil:before {
    content: "\e60a";
}
.icon-pencil-2:before {
    content: "\e60b";
}
.icon-image:before {
    content: "\e60c";
}
.icon-image-2:before {
    content: "\e60d";
}
.icon-images:before {
    content: "\e60e";
}
.icon-music:before {
    content: "\e60f";
}
.icon-headphones:before {
    content: "\e610";
}
.icon-play:before {
    content: "\e611";
}
.icon-film:before {
    content: "\e612";
}
.icon-camera:before {
    content: "\e613";
}
.icon-bullhorn:before {
    content: "\e614";
}
.icon-connection:before {
    content: "\e615";
}
.icon-books:before {
    content: "\e616";
}
.icon-file:before {
    content: "\e617";
}
.icon-profile:before {
    content: "\e618";
}
.icon-file-2:before {
    content: "\e619";
}
.icon-file-3:before {
    content: "\e61a";
}
.icon-file-4:before {
    content: "\e61b";
}
.icon-copy:before {
    content: "\e61c";
}
.icon-copy-2:before {
    content: "\e61d";
}
.icon-copy-3:before {
    content: "\e61e";
}
.icon-paste:before {
    content: "\e61f";
}
.icon-paste-2:before {
    content: "\e620";
}
.icon-paste-3:before {
    content: "\e621";
}
.icon-stack:before {
    content: "\e622";
}
.icon-folder:before {
    content: "\e623";
}
.icon-folder-open:before {
    content: "\e624";
}
.icon-tag:before {
    content: "\e625";
}
.icon-cart:before {
    content: "\e626";
}
.icon-phone:before {
    content: "\e627";
}
.icon-phone-hang-up:before {
    content: "\e628";
}
.icon-address-book:before {
    content: "\e629";
}
.icon-pushpin:before {
    content: "\e62a";
}
.icon-map:before {
    content: "\e62b";
}
.icon-map-2:before {
    content: "\e62c";
}
.icon-history:before {
    content: "\e62d";
}
.icon-clock:before {
    content: "\e62e";
}
.icon-clock-2:before {
    content: "\e62f";
}
.icon-alarm:before {
    content: "\e630";
}
.icon-bell:before {
    content: "\e631";
}
.icon-stopwatch:before {
    content: "\e632";
}
.icon-calendar:before {
    content: "\e633";
}
.icon-calendar-2:before {
    content: "\e634";
}
.icon-print:before {
    content: "\e635";
}
.icon-keyboard:before {
    content: "\e636";
}
.icon-screen:before {
    content: "\e637";
}
.icon-laptop:before {
    content: "\e638";
}
.icon-mobile:before {
    content: "\e639";
}
.icon-mobile-2:before {
    content: "\e63a";
}
.icon-tablet:before {
    content: "\e63b";
}
.icon-tv:before {
    content: "\e63c";
}
.icon-cabinet:before {
    content: "\e63d";
}
.icon-drawer:before {
    content: "\e63e";
}
.icon-drawer-2:before {
    content: "\e63f";
}
.icon-drawer-3:before {
    content: "\e640";
}
.icon-box-add:before {
    content: "\e641";
}
.icon-box-remove:before {
    content: "\e642";
}
.icon-download:before {
    content: "\e643";
}
.icon-upload:before {
    content: "\e644";
}
.icon-disk:before {
    content: "\e645";
}
.icon-storage:before {
    content: "\e646";
}
.icon-undo:before {
    content: "\e647";
}
.icon-redo:before {
    content: "\e648";
}
.icon-flip:before {
    content: "\e649";
}
.icon-flip-2:before {
    content: "\e64a";
}
.icon-undo-2:before {
    content: "\e64b";
}
.icon-redo-2:before {
    content: "\e64c";
}
.icon-forward:before {
    content: "\e64d";
}
.icon-reply:before {
    content: "\e64e";
}
.icon-bubble:before {
    content: "\e64f";
}
.icon-bubble-2:before {
    content: "\e650";
}
.icon-user:before {
    content: "\e651";
}
.icon-users:before {
    content: "\e652";
}
.icon-user-2:before {
    content: "\e653";
}
.icon-users-2:before {
    content: "\e654";
}
.icon-user-3:before {
    content: "\e655";
}
.icon-user-4:before {
    content: "\e656";
}
.icon-busy:before {
    content: "\e657";
}
.icon-spinner:before {
    content: "\e658";
}
.icon-spinner-2:before {
    content: "\e659";
}
.icon-spinner-3:before {
    content: "\e65a";
}
.icon-spinner-4:before {
    content: "\e65b";
}
.icon-search:before {
    content: "\e65c";
}
.icon-zoomin:before {
    content: "\e65d";
}
.icon-zoomout:before {
    content: "\e65e";
}
.icon-expand:before {
    content: "\e65f";
}
.icon-contract:before {
    content: "\e660";
}
.icon-expand-2:before {
    content: "\e661";
}
.icon-contract-2:before {
    content: "\e662";
}
.icon-key:before {
    content: "\e663";
}
.icon-lock:before {
    content: "\e664";
}
.icon-lock-2:before {
    content: "\e665";
}
.icon-unlocked:before {
    content: "\e666";
}
.icon-wrench:before {
    content: "\e667";
}
.icon-settings:before {
    content: "\e668";
}
.icon-equalizer:before {
    content: "\e669";
}
.icon-cog:before {
    content: "\e66a";
}
.icon-cogs:before {
    content: "\e66b";
}
.icon-cog-2:before {
    content: "\e66c";
}
.icon-hammer:before {
    content: "\e66d";
}
.icon-wand:before {
    content: "\e66e";
}
.icon-pie:before {
    content: "\e66f";
}
.icon-stats-2:before {
    content: "\e670";
}
.icon-bars:before {
    content: "\e671";
}
.icon-bars-2:before {
    content: "\e672";
}
.icon-remove:before {
    content: "\e673";
}
.icon-remove-2:before {
    content: "\e674";
}
.icon-signup:before {
    content: "\e675";
}
.icon-list:before {
    content: "\e676";
}
.icon-list-2:before {
    content: "\e677";
}
.icon-numbered-list:before {
    content: "\e678";
}
.icon-menu:before {
    content: "\e679";
}
.icon-menu-2:before {
    content: "\e67a";
}
.icon-tree:before {
    content: "\e67b";
}
.icon-download-2:before {
    content: "\e67c";
}
.icon-upload-2:before {
    content: "\e67d";
}
.icon-download-3:before {
    content: "\e67e";
}
.icon-upload-3:before {
    content: "\e67f";
}
.icon-globe:before {
    content: "\e680";
}
.icon-earth-2:before {
    content: "\e681";
}
.icon-link:before {
    content: "\e682";
}
.icon-flag:before {
    content: "\e683";
}
.icon-attachment:before {
    content: "\e684";
}
.icon-eye:before {
    content: "\e685";
}
.icon-eye-blocked:before {
    content: "\e686";
}
.icon-eye-2:before {
    content: "\e687";
}
.icon-bookmark:before {
    content: "\e688";
}
.icon-star:before {
    content: "\e689";
}
.icon-star-2:before {
    content: "\e68a";
}
.icon-star-3:before {
    content: "\e68b";
}
.icon-thumbs-up:before {
    content: "\e68c";
}
.icon-thumbs-up-2:before {
    content: "\e68d";
}
.icon-warning:before {
    content: "\e68e";
}
.icon-notification:before {
    content: "\e68f";
}
.icon-question:before {
    content: "\e690";
}
.icon-info:before {
    content: "\e691";
}
.icon-info-2:before {
    content: "\e692";
}
.icon-blocked:before {
    content: "\e693";
}
.icon-close:before {
    content: "\e694";
}
.icon-checkmark:before {
    content: "\e695";
}
.icon-minus:before {
    content: "\e696";
}
.icon-plus:before {
    content: "\e697";
}
.icon-enter:before {
    content: "\e698";
}
.icon-exit:before {
    content: "\e699";
}
.icon-play-2:before {
    content: "\e69a";
}
.icon-pause:before {
    content: "\e69b";
}
.icon-stop:before {
    content: "\e69c";
}
.icon-backward:before {
    content: "\e69d";
}
.icon-forward-2:before {
    content: "\e69e";
}
.icon-play-3:before {
    content: "\e69f";
}
.icon-pause-2:before {
    content: "\e6a0";
}
.icon-stop-2:before {
    content: "\e6a1";
}
.icon-backward-2:before {
    content: "\e6a2";
}
.icon-forward-3:before {
    content: "\e6a3";
}
.icon-first:before {
    content: "\e6a4";
}
.icon-last:before {
    content: "\e6a5";
}
.icon-previous:before {
    content: "\e6a6";
}
.icon-next:before {
    content: "\e6a7";
}
.icon-eject:before {
    content: "\e6a8";
}
.icon-volume-high:before {
    content: "\e6a9";
}
.icon-volume-medium:before {
    content: "\e6aa";
}
.icon-loop:before {
    content: "\e6ab";
}
.icon-loop-2:before {
    content: "\e6ac";
}
.icon-loop-3:before {
    content: "\e6ad";
}
.icon-shuffle:before {
    content: "\e6ae";
}
.icon-arrow-up-left:before {
    content: "\e6af";
}
.icon-arrow-up:before {
    content: "\e6b0";
}
.icon-arrow-up-right:before {
    content: "\e6b1";
}
.icon-arrow-right:before {
    content: "\e6b2";
}
.icon-arrow-down-right:before {
    content: "\e6b3";
}
.icon-arrow-down:before {
    content: "\e6b4";
}
.icon-arrow-down-left:before {
    content: "\e6b5";
}
.icon-arrow-left:before {
    content: "\e6b6";
}
.icon-arrow-up-left-2:before {
    content: "\e6b7";
}
.icon-arrow-up-2:before {
    content: "\e6b8";
}
.icon-arrow-up-right-2:before {
    content: "\e6b9";
}
.icon-arrow-right-2:before {
    content: "\e6ba";
}
.icon-arrow-down-right-2:before {
    content: "\e6bb";
}
.icon-arrow-down-2:before {
    content: "\e6bc";
}
.icon-arrow-down-left-2:before {
    content: "\e6bd";
}
.icon-arrow-left-2:before {
    content: "\e6be";
}
.icon-arrow-up-left-3:before {
    content: "\e6bf";
}
.icon-arrow-up-3:before {
    content: "\e6c0";
}
.icon-arrow-up-right-3:before {
    content: "\e6c1";
}
.icon-arrow-right-3:before {
    content: "\e6c2";
}
.icon-arrow-down-right-3:before {
    content: "\e6c3";
}
.icon-arrow-down-3:before {
    content: "\e6c4";
}
.icon-arrow-down-left-3:before {
    content: "\e6c5";
}
.icon-arrow-left-3:before {
    content: "\e6c6";
}
.icon-tab:before {
    content: "\e6c7";
}
.icon-checkbox-checked:before {
    content: "\e6c8";
}
.icon-checkbox-unchecked:before {
    content: "\e6c9";
}
.icon-filter:before {
    content: "\e6ca";
}
.icon-filter-2:before {
    content: "\e6cb";
}
.icon-table:before {
    content: "\e6cc";
}
.icon-table-2:before {
    content: "\e6cd";
}
.icon-paragraph-left:before {
    content: "\e6ce";
}
.icon-paragraph-center:before {
    content: "\e6cf";
}
.icon-paragraph-right:before {
    content: "\e6d0";
}
.icon-paragraph-justify:before {
    content: "\e6d1";
}
.icon-paragraph-left-2:before {
    content: "\e6d2";
}
.icon-paragraph-center-2:before {
    content: "\e6d3";
}
.icon-paragraph-right-2:before {
    content: "\e6d4";
}
.icon-paragraph-justify-2:before {
    content: "\e6d5";
}
.icon-indent-increase:before {
    content: "\e6d6";
}
.icon-indent-decrease:before {
    content: "\e6d7";
}
.icon-newtab:before {
    content: "\e6d8";
}
.icon-embed:before {
    content: "\e6d9";
}
.icon-code:before {
    content: "\e6da";
}
.icon-console:before {
    content: "\e6db";
}
.icon-share:before {
    content: "\e6dc";
}
.icon-mail:before {
    content: "\e6dd";
}
.icon-mail-2:before {
    content: "\e6de";
}
.icon-google:before {
    content: "\e6df";
}
.icon-googleplus:before {
    content: "\e6e0";
}
.icon-googleplus-2:before {
    content: "\e6e1";
}
.icon-googleplus-3:before {
    content: "\e6e2";
}
.icon-google-drive:before {
    content: "\e6e3";
}
.icon-facebook:before {
    content: "\e6e4";
}
.icon-facebook-2:before {
    content: "\e6e5";
}
.icon-instagram:before {
    content: "\e6e6";
}
.icon-twitter:before {
    content: "\e6e7";
}
.icon-twitter-2:before {
    content: "\e6e8";
}
.icon-x:before {
    content: "\1D54F";
}
.icon-feed:before {
    content: "\e6e9";
}
.icon-feed-2:before {
    content: "\e6ea";
}
.icon-youtube:before {
    content: "\e6eb";
}
.icon-youtube-2:before {
    content: "\e6ec";
}
.icon-tumblr:before {
    content: "\e6ed";
}
.icon-tumblr-2:before {
    content: "\e6ee";
}
.icon-apple:before {
    content: "\e6ef";
}
.icon-finder:before {
    content: "\e6f0";
}
.icon-android:before {
    content: "\e6f1";
}
.icon-windows:before {
    content: "\e6f2";
}
.icon-windows-8:before {
    content: "\e6f3";
}
.icon-skype:before {
    content: "\e6f4";
}
.icon-reddit:before {
    content: "\e6f5";
}
.icon-linkedin:before {
    content: "\e6f6";
}
.icon-stackoverflow:before {
    content: "\e6f7";
}
.icon-pinterest:before {
    content: "\e6f8";
}
.icon-xing:before {
    content: "\e6f9";
}
.icon-yelp:before {
    content: "\e6fa";
}
.icon-libreoffice:before {
    content: "\e6fb";
}
.icon-file-pdf:before {
    content: "\e6fc";
}
.icon-file-openoffice:before {
    content: "\e6fd";
}
.icon-file-word:before {
    content: "\e6fe";
}
.icon-file-excel:before {
    content: "\e6ff";
}
.icon-file-zip:before {
    content: "\e700";
}
.icon-file-powerpoint:before {
    content: "\e701";
}
.icon-file-xml:before {
    content: "\e702";
}
.icon-file-css:before {
    content: "\e703";
}
.icon-html5:before {
    content: "\e704";
}
.icon-chrome:before {
    content: "\e705";
}
.icon-firefox:before {
    content: "\e706";
}
.icon-IE:before {
    content: "\e707";
}
.icon-opera:before {
    content: "\e708";
}
.icon-safari:before {
    content: "\e709";
}
.icon-IcoMoon:before {
    content: "\e70a";
}
.icon-earth:before {
    content: "\e605";
}
.icon-stats:before {
    content: "\e606";
}
.icon-document-alt-fill:before {
    content: "\e600";
}
.icon-book-alt-2:before {
    content: "\e601";
}
.icon-pen:before {
    content: "\e602";
}
.icon-tag-fill:before {
    content: "\e603";
}
.icon-paperclip:before {
    content: "\e604";
}
.icon-hamburger:before {
    content: "\e726";
}
.icon-grid:before {
    content: "\e727";
}
.icon-uniE723:before {
    content: "\e723";
}
.icon-tv4:before {
    content: "\e724";
}
.icon-radio:before {
    content: "\e725";
}
.icon-export:before {
    content: "\e719";
}
.icon-tools2:before {
    content: "\e900";
}
.icon-earth-3:before {
    content: "\e71a";
}
.icon-list-3:before {
    content: "\e71b";
}
.icon-arrow-left-4:before {
    content: "\e71c";
}
.icon-arrow-down-4:before {
    content: "\e71d";
}
.icon-arrow-up-4:before {
    content: "\e71e";
}
.icon-twitter-3:before {
    content: "\e71f";
}
.icon-facebook-3:before {
    content: "\e720";
}
.icon-facebook-4:before {
    content: "\e721";
}
.icon-googleplus-4:before {
    content: "\e722";
}
.icon-tv-3:before {
    content: "\e716";
}
.icon-mail-3:before {
    content: "\e717";
}
.icon-world:before {
    content: "\e718";
}
.icon-tv-2:before {
    content: "\e712";
}
.icon-camera-2:before {
    content: "\e713";
}
.icon-microphone:before {
    content: "\e714";
}
.icon-chat-2:before {
    content: "\e715";
}
