//
// Labels and badges
// --------------------------------------------------


// Base classes
.label,
.badge {
  display: inline-block;
  padding: 2px 4px;
  font-size: @baseFontSize * .846;
  font-weight: bold;
  line-height: 14px; // ensure proper line-height if floated
  color: @white;
  vertical-align: baseline;
  white-space: nowrap;
  //text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  background-color: @grayLight;
}
// Set unique padding and border-radii
.label {
//  .border-radius(3px);
}
.badge {
  padding-left: 9px;
  padding-right: 9px;
//  .border-radius(9px);
}

// Empty labels/badges collapse
.label,
.badge {
  &:empty {
    display: none;
  }
}

// Hover/focus state, but only for links
a {
  &.label:hover,
  &.label:focus,
  &.badge:hover,
  &.badge:focus {
    color: @white;
    text-decoration: none;
    cursor: pointer;
  }
}

// Colors
// Only give background-color difference to links (and to simplify, we don't qualifty with `a` but [href] attribute)
.label,
.badge {
  // Important (red)
  &-important         { background-color: @errorText; }
  &-important[href]   { background-color: darken(@errorText, 10%); }
  // Warnings (orange)
  &-warning           { background-color: @orange; }
  &-warning[href]     { background-color: darken(@orange, 10%); }
  // Success (green)
  &-success           { background-color: @successText; }
  &-success[href]     { background-color: darken(@successText, 10%); }
  // Info (turquoise)
  &-info              { background-color: @infoText; }
  &-info[href]        { background-color: darken(@infoText, 10%); }
  // Inverse (black)
  &-inverse           { background-color: @grayDark; }
  &-inverse[href]     { background-color: darken(@grayDark, 10%); }
}

// Quick fix for labels/badges in buttons
.btn {
  .label,
  .badge {
    position: relative;
    top: -1px;
  }
}
.btn-mini {
  .label,
  .badge {
    top: 0;
  }
}

// Tags
.tags .tag,
.searchTerm,
.contactTag {
  font-size: @baseFontSize;
  color: @white !important;
  vertical-align: baseline;
  white-space: nowrap;
  background: @grayLight;
  content: @white;
  float: left;
  padding: @gridSquare * 0.5 @gridSquare * 1;
  margin-right: @gridSquare * 1;
  margin-bottom: @gridSquare * 1;
  position: relative;

  &:hover {
    text-decoration: none;
    background-color: darken(@grayLight, 10%);
    color: @white;
  }

  &:active {
    text-decoration: none;
    background-color: @grayDark;
  }
}
.tags {
  margin-top: 4px;
  display: inline-block !important;
}
.tags .tag {
  margin-left: 10px;
  margin-right: 8px;
  margin-bottom: 4px;
  padding-left: ((@gridSquare * 0.5) + 2px);
  padding-top: 3px;
  padding-bottom: 1px;
  line-height: 100%;
  height: 16px;
  white-space: nowrap; //padding-top: 3px \9; // IE8 and IE9
//padding-bottom: 5px \9; // IE8 and IE9

  &.small{
    &:before {
      border: 8px solid transparent !important;
    }
  }

  &:before {
    content: "";
    position: absolute;
    left: -7px; top: 0px;
    border: 10px solid transparent;
    border-left: none;
    border-right: 7px solid @grayLight;
  }

  &:hover:before { border-right-color: darken(@grayLight, 10%); }
  &:active:before { border-right-color: darken(@grayLight, 10%); }

  span.arrow, .btnDel {
    display: none; // display: block; // Sprite arrows have been replaced with before-pseudo-elements
    width: 12px;
    height: 24px;
    position: absolute;
    .background-sprite(10, 10);
    left: -9px;
    top: 0;
  }
  span.smallArrow {
    display: none; // display: block; // Sprite arrows have been replaced with before-pseudo-elements
    width: 12px;
    height: 24px;
    position: absolute;
    .background-sprite(11, 10);
    left: -9px;
    top: 0;
  }

  &:hover span.arrow {
    //.background-sprite(10, 11);
  }

  &:hover:active span.arrow {
    //.background-sprite(10, 12);
  }

}
