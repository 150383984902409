/* Customer-Administration ################################################## */
#adminPortalKunde {

  div.tooltip {
    white-space: nowrap;
  }
  div.tooltip > .tooltip-inner {
    margin-right: 80px;
  }

  select {
    width: 190px;
  }

  .useradminTab {
    width: 100%;
    border-top: 1px solid darkgray; //  border-bottom: 1px solid darkgray;

    .new {
      font-weight: bold;
    }

    .form_errors ul {
      margin-left: 0;

      li {
        list-style-type: none;
      }
    }
  }

  .useradminTab > .headline {
    background-color: @usrAdmHeadlineBkColor; /*@grayLighter #F0F0F0*/
    color: @usrAdmHeadlineColor;
    padding: 15px;
  }

  .useradminTab > .content {
    border-top: 1px solid darkgray;
    padding: 6px 18px;
    text-align: left;
    vertical-align: text-top;
  }

  .useradminTab > .editContent {
    background-color: @usrAdmEditFieldBkColor;
    border-top: 1px solid darkgray;
    padding: 6px 18px;
    text-align: left;
    vertical-align: text-top;
  }

  .editContentGapButtom {
    margin-bottom: 10px;
  }

  .useradminTab > .contentPlain {
    padding: 1px 18px;
    text-align: left;
    vertical-align: text-top;
  }

  .useradminTab > .editContentPlain {
    background-color: @usrAdmEditFieldBkColor;
    padding: 1px 18px;
    text-align: left;
    vertical-align: text-top;
  }

  .editFrame {
    border: 2px solid @usrAdminBorder;
    background-color: @usrAdmEditFieldBkColor;
  }

  .content_right {
    text-align: right;
  }

  .content_bottom {
    vertical-align: bottom;
  }

  .useradminTab * input[type=text] {
    width: 170px;
  }

  .useradminTab ul.select2-choices {
    width: 700px;
    min-height: 52px !important;
  }

  .useradminTab * button {
    width: 130px;
    text-align: left;
  }

  .displayInline {
    display: inline;
  }

  .firstLine {
    padding-bottom: 4px;
    font-weight: bold;
  }

  .italic {
    font-style: italic;
  }

  .subHeadline {
    padding: 4px;
    font-weight: bold;
  }

  .displayBlock {
    display: block;
  }

  .btn182 {
    width: 182px !important;
  }

  .btn182important {
    width: 182px !important;
    border: 2px solid @black;
  }

  .btn200:not(.editorButton) {
    width: 200px !important;
  }

  .btn {
    margin-top: 4px;
  }

  #PortalUser_title, #ausschnitt_invitation_type_title {
    width: 70px;
  }

  input.inputWidthPortalUser, #PortalUser_password_password, #PortalUser_password_confirm {
    width: 140px !important;
  }

  textarea.inputWidthPortalUser {
    width: 560px;
    height: 45px;
  }

  select.inputWidthPortalUser {
    width: 150px !important;
  }

  .formCheckboxes input, .formCheckboxes label {
    float: left;
    padding-left: 10px;
  }

  .formCheckboxes input {
    clear: left;
  }

  .ssoType {
    display: flex;
    align-items: flex-start;
    > * {
      margin-right: 15px;
    }
  }

  .rowSpace {
    padding-top: 8px;
  }

  .rowSpaceBottom {
    padding-bottom: 8px;
  }

  .spanSpaceLeft {
    margin-left: 8px;
  }

  //  extends spectrum color picker
  input[type=text] {
    height: 20px !important;
  }

  .contentEditor {
    width: auto !important;
  }
  .editor-toolbar {
    width: auto !important;
  }

  #btn_export_emails>i.icon-download {
    color: @color2;
  }

  .portalUserRow:nth-child(odd) {
    background-color: @gray9;
  }

  .emailGroupRow:nth-child(odd) {
    background-color: @gray9;
  }

  .emailGroupRow {
    margin-right: 44px;
  }


  .emailUploadTitle {
    padding-right: 2px;
  }

  .emailRow:nth-child(even) {
    background-color: @gray9;
  }

  .portalUserRow:hover, .emailGroupRow:hover, .emailRow:hover {
    background-color: @color3;
    color: @color2;
  }

  [id^=grId_].selectedGroup, [id^=grRowId_].selectedGroup {
    background-color: @color3;
    color: @color2;
    font-weight: bold;
  }

  #mrViewButtonChoice label {
    display: inline-block;
    padding-left: 2px;
  }
}

.registationConfirmation {
  #contentContainer {
    background: white;
    padding-top: 10px;
  }

  .input_username_w_prefix {
    width: 103px !important;
  }

  .username_w_prefix {
    text-align: right;
    width: 95%
  }

  .regConfirmationHeader {
    text-align: center;
    vertical-align: bottom;
    width: 95%;
    margin-top: 10px;
    font-weight: bold;
    font-size: 2.4em;
    color: @color0;
    line-height: 30px;
  }
}

#selArtMailForm, #formMail, #newsletterForm {
  input.selArtField {
    width: 260px !important;
  }

  textarea.selArtField {
    width: 260px;
    height: 84px;
  }

  select.selArtField {
    width: 274px !important;
  }

  .rowSelect {
    margin: 5px;
  }
}

#taglistContent {
  .row {
    min-height: 25px;
  }
  .rowCenter {
    text-align: center;
  }
  .rowLeft {
    text-align: left;
  }
  .rowRight {
    text-align: right;
  }
}

// Products
#productListContent {
  .product_row {
    margin: 6px;
    border-bottom: 1px solid @productsListBorder;
    min-height: 28px;
  }
}

#work_index {
  .work_index_row {
    border-top: 1px solid @grayLighterBorder;
    margin: 0 40px;
  }

  .work_cell {
    min-height: 160px;
    padding-top: 40px;
    border-right: 1px solid @grayLighterBorder;
    text-align: left;
    float: left;
  }

  .work_cell_right {
    border-right: none;
  }

  .workicon {
    .box-sizing(border-box);
    text-align: right;
    padding: 8px;
    margin-top: 10px;
  }

  .worktext {
    .box-sizing(border-box);
    padding: 8px;
  }

  ul {
    list-style: none outside none;
    margin: 0;
  }

  h3 {
    margin-bottom: 4px;
    color: @grayDarker;
    font-size: 22px;
    line-height: 130%;
    font-weight: bold;
  }
  li {
    color: @grayDarker;
  }
  .count {
    color: @linkColor;
  }
}

#export {
  ul {
    list-style: none outside none;
    margin: 0;
  }
  li {
    color: @grayDarker;
    vertical-align: middle;
    padding-bottom: 1px;
  }
  .headline {
    margin-top: 0;
    font-weight: bold;
    font-size: 14px;
  }
  .exportHeadline {
    font-size: 1.2em;
    font-weight: bold;
    text-align: left;
    .box-sizing(border-box);
  }

  .editContent {
    padding: 0 24px;
    .box-sizing(border-box);
  }
  .useradminTab > .editContent, .useradminTab > .editContentPlain {
    background-color: @white !important;
    //    background-color: @exportEditFieldBkColor !important;
  }
  input[type="radio"] {
    margin: 0 2px 4px 4px;
  }
  .export_control {
    .box-sizing(border-box);
    padding: 24px;
  }

  .export-radio-filetype {
    display: inline-block;
    padding: 0 16px 0 0;
  }

  #btn_export {
    margin-right: 24px;
  }

  .detailList {
    /*display: none;*/
  }

  .export_fields, .export_mkz {

    // .headline { padding-left: 20px; }
    border-right: 1px solid @white;

    li {
      input {
        margin-right: 8px;
      }
      label {
        background-color: @grayLighter;
        margin-bottom: 0;
        padding: 8px 16px;

        &:hover, &:active, &:focus {
          position: relative;
          z-index: 1;
          background-color: @grayLighter;
          .box-shadow(0 0 5px 0px rgba(0, 0, 0, 0.2));
        }

        &.export_checkall {
          background-color: #fff;

          &:hover, &:active, &:focus {
            background-color: #fff;
            .box-shadow(none);
          }
        }
      }
      label.sectionHeader {
        background-color: @color1;
        color: @color10;
        cursor: move;
      }

    }
  }

  .export_fields {
    .export_kennzahlen, .export_selection {
      position: relative;
      background-color: @gray9;

      &:hover, &:active, &:focus {
        background-color: @gray9;
      }

      span {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
  }
  .export_mkz {
    li label {
      background-color: @gray9;
    }
  }

  .config-name-row {
    padding-right: 0;
    .btn {
      margin-left: 10px;
    }
  }

  .configEditButton {
    width: 30px;
    place-items: center;
  }

  .btn-toggle-edit {
    background: none;
    border: none;
    color: @textColor;
  }

}

#profile_admin {
  .records_list {
    margin: 5px;
    td {
      border: 1px solid @grayLighterBorder;
      padding: 4px;
    }
  }
}

.fos_user_user_show {
  .bold {
    font-weight: bold;
    color: @grayDarker;
  }
  div {
    margin: 4px;
  }
}

.linkColorNegative {
  color: @linkColorNegative;
}

#blackBoard {
  .contentElementCellLeft {
    line-height: 8px;
    max-height: 32px;
  }
  .noLink {
    display: block;
    padding: 4px 2px 4px 8px;
    transition: background 0.15s linear 0s;
  }
}

#auftragKonfigDiv {
  .fixedHeader {
    position: fixed;
    background-color: @bodyBackground;
    padding-top: 20px;
    .shadow(gray);
  }
}

#auftragKonfigForm {
  #auftragKonfigFields {
    padding-top: 180px;
  }
  #auftragKonfigFields, #akKonfigThemenRow {
    > div:nth-child(even) {
      background-color: @gray10;
      border-top: 1px solid @gray9;
    }
    > div:nth-child(odd) {
      border-top: 1px solid @gray9;
    }

    .tableRow span {
      display: inline-block;
      vertical-align: middle;
      line-height: 40px
    }
    input[type="checkbox"] {
      margin-left: 25px;
      margin-top: 10px;
    }
  }

  #akKonfigButtonRow {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid @color1;
  }

  div[id*="selectBox"], div[id*="tonalitaet"] {
    > label {
      display: inline;
    }
    > input {
      margin-left: 5px;
    }
  }
  .marginLeft10 {
    margin-left: 10px;
  }
  .marginLeft20 {
    margin-left: 20px;
  }
  .marginLeft40 {
    margin-left: 40px;
  }
  .paddingHeader {
    padding-right: 27px;
  }
  .input input {
    width: 130px;
  }
  input:disabled {
    display: none;
  }
  span.disabled {
    display: none !important;
  }
  .radioLabel {
    margin-left: 1px;
    margin-right: 2px;
  }
  .rightTooltip {
    .tooltip {
      left: 0 !important;
    }
    .tooltip-arrow {
      left: 10% !important;
    }
  }
  .tooltip-inner {
    font-weight: normal;
  }
}

/* image picker styles */
ul.thumbnails.image_picker_selector {
  overflow: auto;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul.thumbnails.image_picker_selector ul {
  overflow: auto;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul.thumbnails.image_picker_selector li.group_title {
  float: none;
  font-weight: bold;
  font-size: 1.2em;
}

ul.thumbnails.image_picker_selector li {
  margin: 0 12px 18px 0;
  float: left;
}

ul.thumbnails.image_picker_selector li .thumbnail {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  height: 150px;
  border: 0;
  box-shadow: none;
  padding: 0;
}

ul.thumbnails.image_picker_selector li .thumbnail img,
.rubrikThumbnail img {
  padding: 6px;
  border: 1px solid #dddddd;
  -webkit-user-drag: none;
  width: 100px;
  background: #ffffff;
}

ul.thumbnails.image_picker_selector li .thumbnail.selected img {
  background: @color3;
}

/* rubrik image management */
.rubrikThumbnailPlaceholder {
  border: 1px solid #dddddd;
  width: 100px;
  background: #ffffff;
  display: block;
  padding: 12px 6px;
  text-align: center;
}

.rubrikImageRow {
  margin: 10px 0 0 0;
  padding: 10px 0 0 0;
}

.rubrikImageRow:first-of-type {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.rubrikImageRow + .rubrikImageRow {
  border-top: 1px solid darkgray
}

#auftrag_statistics_form {
  .datepicker {
    width: 70px !important;
  }
  margin: 0 0 0 0;
}

#auftrag_statistics, #auftrag_statistics_result {
  .row {
    padding-top: 6px;
    margin-bottom: 6px;
  }
  .content_right {
    text-align: right;
  }
  .rowDivider {
    border-top: 1px solid @grayLight;
  }
}
